import React, { useEffect, useState, useRef } from 'react';
import { unmountComponentAtNode } from "react-dom";
import { Layout, Input, InputNumber, Radio, Checkbox, Space, DatePicker, TimePicker, Button, Dropdown, Form, List, Collapse } from 'antd';
import { DownOutlined, EyeOutlined, EditOutlined, RollbackOutlined, DeleteOutlined } from '@ant-design/icons';
import style from './styles/WpSetParentChildCourse.module.css';
import '../styles/WordpressAnt.css';
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";

/*** Import Wordpress Component ***/
// import WordpressCastInterface from "./WordpressComponents/WordpressCastInterface"

function WpSetParentChildCourse() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const [adminData, setAdminData] = useState({});
    const [btnLoadings, setBtnLoadings] = useState([]);
    const [sendingTime, setSendingTime] = useState(0);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [showInputNumber, setShowInputNumber] = useState(false);
    const [checkList, setCheckList] = useState([{}]);
    const [dropdownTextState, setDropdownTextState] = useState("請選擇教室編號")
    const [afterFilterShow, setAfterFilterShow] = useState({"display": "block"}); // 篩選完顯示區塊
    const [defaultSecCount, setDefaultSecCount] = useState(8);
    const [addSecCount, setAddSecCount] = useState(0);
    const [addSecCountArray, setAddSecCountArray] = useState([0]);
    const [addSectionArray, setAddSectionArray] = useState([]);
    const classSecRef = useRef(null);
    const [classDate, setClassDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [classArray, setClassArray] = useState([{id: "", location: "", startTime: "", endTime: ""}]);
    const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState({ pid: "", name: "", courseIntroduction: "", price: 0, teacher: "", maximum: 8, formal: 6, toExperience: 1, compensate: 1, locationTime: [], flag: 1, operator: ""}); // 篩選欄位onChange狀態
    const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState({ pid: "", name: "", courseIntroduction: "", price: 0 }); // 更新欄位onChange狀態
    const [courseDataObj, setCourseDataObj] = useState({});
    const [locationTimeArray, setLocationTimeArray] = useState([]);
    const [courseDataArray, setCourseDataArray] = useState([]); // 課程資料
    const [courseDataArrayUnique, setCourseDataArrayUnique] = useState([]); 
    useEffect(()=>{console.log(courseDataArrayUnique)},[courseDataArrayUnique]);
    const [courseDataArrayUniqueTest, setCourseDataArrayUniqueTest] = useState([]); 
    const [courseSettingSuccess, setCourseSettingSuccess] = useState(false);
    const [courseDataArrayTest, setCourseDataArrayTest] = useState([
        {
          "_id": "64911e87466aef5f4a19f998",
          "pid": "string",
          "courseIntroduction": "課程介紹:Nestjs-API,MongoDB環境建置",
          "formal": 6,
          "toExperience": 2,
          "compensate": 0,
          "memo": "備註:",
          "flag": 0,
          "createdDate": "2023-06-20T03:35:25.980Z",
          "__v": 0,
          "student": []
        },
        {
          "_id": "64911eeb4ddde2728f9b4c7b",
          "pid": "course-110-0002",
          "name": "課程名稱:Nestjs-API及MongoDB環境建置(第一章)",
          "location": "捷運善導寺站-店1",
          "startTime": "2023-06-19T09:00:01.481Z",
          "endTime": "2023-06-19T09:30:01.481Z",
          "flag": 0,
          "createdDate": "2023-06-19T01:32:01.481Z",
          "student": [],
          "__v": 0
        },
        {
            "_id": "64911eeb4ddde2728f9b4c7b",
            "pid": "course-110-0002",
            "name": "課程名稱:Nestjs-API及MongoDB環境建置(第一章)",
            "location": "捷運善導寺站-店2",
            "startTime": "2023-06-20T09:00:01.481Z",
            "endTime": "2023-06-20T09:30:01.481Z",
            "flag": 0,
            "createdDate": "2023-06-19T01:32:01.481Z",
            "student": [],
            "__v": 0
        },
        {
            "_id": "64911eeb4ddde2728f9b4c7b",
            "pid": "course-110-0002",
            "name": "課程名稱:Nestjs-API及MongoDB環境建置(第一章)",
            "location": "捷運善導寺站-店3",
            "startTime": "2023-06-21T09:00:01.481Z",
            "endTime": "2023-06-21T09:30:01.481Z",
            "flag": 0,
            "createdDate": "2023-06-19T01:32:01.481Z",
            "student": [],
            "__v": 0
        },
        {
          "_id": "64911f5a466aef5f4a19f99b",
          "pid": "string",
          "courseIntroduction": "課程介紹:Nestjs-API,MongoDB環境建置",
          "formal": 6,
          "toExperience": 2,
          "compensate": 0,
          "memo": "備註:",
          "flag": 0,
          "createdDate": "2023-06-20T03:35:25.980Z",
          "__v": 0,
          "student": []
        }
    ]); // 課程資料 

    /*** initialize ***/
    const initialize = () => {
        setFilterFieldsOnChangeState({ pid: "", name: "", courseIntroduction: "", price: 0, teacher: "", maximum: 8, formal: 6, toExperience: 1, compensate: 1, locationTime: [], flag: 1, operator: ""});
        setUpdateFieldsOnChangeState({ pid: "", name: "", courseIntroduction: "", price: 0 });
        setLocationTimeArray([]);
        setCourseDataArrayUnique([]); 
        setAddSectionArray([]);
        setClassDate(dayjs().format("YYYY-MM-DD"));
        setClassArray([{id: "", location: "", startTime: "", endTime: ""}]);
    }
    /*** CourseData去除有重複pid的obj ***/
    useEffect(()=>{
        const makeUniqueArray = (arr, key) => {
            const uniqueObjects = {};
            return arr.reduce((result, obj) => {
                if( obj?.flag > -1 ) { // 非刪除項才做處理
                    if (!uniqueObjects[obj[key]]) {
                        uniqueObjects[obj[key]] = true;
                        result.push(obj);
                    }
                } 
                return result;
            }, []);
        };
        const uniqueArray = makeUniqueArray(courseDataArray, 'pid');
        // const uniqueArrayTest = makeUniqueArray(courseDataArrayTest, 'pid');
        setCourseDataArrayUnique(uniqueArray);
        // setCourseDataArrayUniqueTest(uniqueArrayTest);
    },[courseDataArray]);
        
    /*** 新增一個課堂object array ***/
    // useEffect(()=>{
    //     const targetKeys = ['_id', 'location', 'startTime', 'endTime'];  // 要匹配的特定键名
    //     const filteredObjectsTest = courseDataArrayTest.map(obj => {
    //         let newObj = {};
    //         targetKeys.forEach(key => {
    //             if (obj.hasOwnProperty(key)) {
    //                 newObj[key] = obj[key];
    //             }
    //         });
    //         return newObj;
    //     });
    //     setLocationTimeArray(filteredObjectsTest);
    // },[courseDataArrayTest]);   
    useEffect(()=>{
        console.log(locationTimeArray)
    },[locationTimeArray]);   
    const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項資料, 第n項是否可編輯]
    // const [courseObj, setCourseObj] = useState({pid: "", name: "", courseIntroduction: "", price: ""});
    // useEffect(()=>{console.log(courseObj)},[courseObj]);
    const wpApiURL = process.env.REACT_APP_WPAPIURL; // API URL
    const loginToken = window.localStorage.getItem("loginToken");
    // const [courseDataArray, setCourseDataArray] = useState([
    //     { _id: "", name: "", location: "", startTime: "", endTime: "" },
    // ]); // 課程資料
    useEffect(() => {
        if( filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.toExperience - filterFieldsOnChangeState?.compensate < 0 ) {
            setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["formal"]: 0 });
        } else if( filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.compensate < 0 ) {
            setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["toExperience"]: 0 });
        } else if( filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.toExperience < 0 ) {
            setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["compensate"]: 0 });
        }
    }, []);

     /*** 查詢課堂 ***/
     const requestClass = async(e, id, name, location, startTime, endTime) => {
        e?.preventDefault();
        let url = "";
        if( !! id ) {
            url = wpApiURL + `/kidproCourse/${id}`;
        } else {
            url = wpApiURL + "/kidproCourse/getCourseFindall";
        }
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "application/json",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`課程查詢失敗！\n${err}`);
            throw new Error("Request Course failed");
        });
        console.log(response);
        // if( response.code === 200 ) {
        // const responseData = response?.data || [];
        // console.log(responseData);
        setCourseDataArray(response); 
        setIsEditMode([-1, false]); // 返回非編輯模式
        
        const targetKeys = ['pid', 'location', 'startTime', 'endTime'];  // 要匹配的特定键名
        const filteredObjects = response.map(obj => {
            let newObj = {};
            if( obj?.flag > -1 ) {
                targetKeys.forEach(key => {
                    if (obj.hasOwnProperty(key)) {
                        newObj[key] = obj[key];
                    }
                });
            }
            return newObj;
        });
        setLocationTimeArray(filteredObjects);

        // }
    }
    useEffect(() => {
        requestClass();
    }, []);
    useEffect(() => {
        requestClass();
    }, [courseSettingSuccess]);
    

    // useEffect(() => {
    //     let addSecArrayTemp = [];
    //     let addSecCountArrayTemp = [];
    //     for( let i=0; i<addSecCount; i++ ) {
    //         addSecArrayTemp.push([i, classNode(i)]);
    //         addSecCountArrayTemp.push(i)
    //         setAddSectionArray( addSecArrayTemp );
    //         setAddSecCountArray(addSecCountArrayTemp);
    //     }
    // }, [addSecCount, addSecCountArray]);

    /*** 新增課堂 ***/
    const addClass = (e) => {
        e?.preventDefault();
        setAddSectionArray([...addSectionArray, { id: Date.now() }]);
    }
    // const addClass = (e) => {
    //     e?.preventDefault();
    //     let addSectionArrayTemp = addSectionArray;
    //     let count = addSecCount;
    //     addSectionArrayTemp.push([count, classNode(count)]);
    //     setAddSectionArray(addSectionArrayTemp);
    //     setAddSecCount(++count);
    //     // const input = document.getElementById("WpSetParentChildCourseSectionAddClass").getElementsByTagName("input")[0];
    //     // input.value = "";
    // }

    /*** 刪除課堂 ***/
    const removeClass = (e, id) => {
        e?.preventDefault();
        const classSections = addSectionArray.filter((item) => item.id !== id);
        setAddSectionArray(classSections);
    }    
    // const removeClass = (e, idx) => {
    //     e?.preventDefault();
    //     console.log(idx);
    //     if( idx > -1 ) {
    //         let addSectionArrayTemp = addSectionArray;
    //         // let count = addSecCount;
    //         // console.log(count);
    //         for( let el of addSectionArrayTemp ) {
    //             console.log(el[0]);
    //             console.log(idx);
    //             if( el[0] === idx ) {
    //                 addSectionArrayTemp.splice(addSectionArrayTemp.indexOf(el), 1);
    //             }
    //         }
    //         setAddSectionArray(addSectionArrayTemp);
    //         // setAddSecCount(--count);
    //         // const input = document.getElementById("WpSetParentChildCourseSectionAddClass").getElementsByTagName("input")[0];
    //         // input.value = "";
    //     }
    // }
    // const removeClassOri = (e, idx) => {
    //     e.preventDefault();
    //     let nodeId = e?.target?.parentNode?.id || -1;
    //     console.log(nodeId);
    //     nodeId = parseInt(nodeId) > -1 ? parseInt(nodeId) : -1;
    //     console.log(nodeId);
    //     for( let [idx, elNode] of addSectionArray ) {
    //         console.log(idx);
    //         console.log(elNode);
    //         if( nodeId > -1 && idx === nodeId ) {
    //             console.log(idx);
    //             console.log(elNode);
    //             let addSectionArrayTemp = addSectionArray;
    //             addSectionArrayTemp.splice(idx, 1);
    //             setAddSectionArray(addSectionArrayTemp);
    //             let count = addSecCount;
    //             setAddSecCount(--count);
    //         } 
    //     }
    // }

    // useEffect(() => {
    //     let addSecArrayTemp = [];
    //     for( let i=0; i<addSecCount; i++ ) {
    //         addSecArrayTemp.push(i)
    //         setAddSectionArray( addSecArrayTemp );
    //     }
    // }, [addSecCount]);
    useEffect(() => {
        console.log(addSecCount);
        console.log(addSectionArray);
    }, [addSecCount]);
    useEffect(() => {
        console.log(addSectionArray);
    }, [addSectionArray]);
    useEffect(()=>{console.log(courseDataObj)},[courseDataObj]);
    useEffect(()=>{console.log(filterFieldsOnChangeState)},[filterFieldsOnChangeState]);

    /*** 取得管理者資料 ***/
    const getAdminData = async() => {
        const decoded = await jwt_decode(loginToken);
        console.log(decoded);
        const id = await decoded?.id || "";
        const email = await decoded?.email || "";
        const atIndex = await email.indexOf('@');
        const name = await atIndex !== -1 ? email.substring(0, atIndex) : "";
        const data = {id, name, email};
        console.log(name);
        // setAdminData(data);
        setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["operator"]:await name });
    }
    useEffect(() => {
        getAdminData();
    }, []);
    useEffect(() => {
        getAdminData();
    }, [loginToken]);
    /*** END 取得管理者資料 ***/    

    const items = [
        {
            label: 'RM301',
            key: 'RM301',
            onClick: ({ item, key, keyPath, domEvent }) => {
                console.log(key);
                setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["location"]:key });
                setDropdownTextState(key);
            },
        },
        {
            label: 'RM302',
            key: 'RM302',
            onClick: ({ item, key, keyPath, domEvent }) => {
                console.log(key);
                setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["location"]:key });
                setDropdownTextState(key);
            },
        },
    ];

    const enterLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = true;
          return newLoadings;
        });
    };

    const endLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    }

    const fieldsOnChange = (e, idx) => {
        e?.preventDefault();
        const fieldsId = e?.target?.id || "";
        const value = e?.target?.value || "";
        console.log(fieldsId, value);
        switch(fieldsId) {
            case "coursePID":
                setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["pid"]:value});
                break;
            case "courseName": 
                setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["name"]:value});
                break;
            case "coursePrice":  
                setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["price"]:value});
                break;
            case "courseIntro":  
                setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["courseIntroduction"]:value});
                break;
            case "classLocation":
                const classArrayNew = [...classArray].map(object => {
                    if( ! object.id ) { // 只有預設空值的Object Element ID，將Object Element賦予id
                        return {
                            ...object,
                            id: idx,
                            location: value
                        }
                    } else if( object.id === idx ) { // Object Element ID符合目標，更新Object Element
                        return {
                            ...object,
                            location: value
                        }
                    }
                    else return object; // Object Element ID不符合目標，不更動Object Element
                });
                setClassArray(classArrayNew);
                let flagObjHasSameId = false;
                for( let object of classArrayNew ) {
                    if( object.id === idx ) {
                        flagObjHasSameId = true;
                    }
                }        
                if( ! flagObjHasSameId ) {
                    setClassArray(prevArray => [...prevArray, {
                        id: idx,
                        location: value
                    }]);
                }                
                break;    
        }
    }

    const updateFieldsOnChange = (e, defVal) => {
        e?.preventDefault();
        const fieldsId = e?.target?.id || "";
        const value = e?.target?.value || "";
        console.log(fieldsId, value);
        switch(fieldsId) {
            case "coursePID":
                setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["pid"]:value});
                break;
            case "courseName": 
                setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["name"]:value});
                break;
            case "coursePrice":  
                setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["price"]:value});
                break;
            case "courseIntro":  
                setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["courseIntroduction"]:value});
                break;
        }
    }
    // const locationOnChange = (e, id) => {
    //     e?.preventDefault();
    //     const fieldsId = e?.target?.id || "";
    //     const value = e?.target?.value || "";
    //     let classObj = {};

    //     switch(fieldsId) {
    //         case "location":  
    //             setClassObj({...classObj, ["location"]:value});
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["locationTime"]:classObj});
    //             break;
    //         case "location":  
    //             setClassObj({...classObj, ["location"]:value});
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["locationTime"]:classObj});
    //             break;
    //         case "location":  
    //             setClassObj({...classObj, ["location"]:value});
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["locationTime"]:classObj});
    //             break;                                
    //     }
    // }
    const inputNumberPriceOnChange = (value) => {
        console.log(value);
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["price"]:value}); 
    }
    const inputNumberClassNumberOnChange = (value) => {
        console.log(value);
        // setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["numClasses"]:value}); 
        setDefaultSecCount(value);
    }
    const inputNumberMaximumOnChange = (value) => {
        console.log(value);
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["maximum"]:value}); 
    }
    const inputNumberFormalOnChange = (value) => {
        console.log(value);
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["formal"]:value}); 
    }
    const inputNumberExpOnChange = (value) => {
        console.log(value);
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["toExperience"]:value}); 
    }
    const inputNumberCompensateOnChange = (value) => {
        console.log(value);
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["compensate"]:value}); 
    }
    const radioOnChange = (e) => {
      console.log('radio checked', e.target.value);
      setSendingTime(e.target.value);
    };
    
    const datePickerOnChange = (date, dateString, id) => {
        console.log(date, dateString, id);
        setClassDate(dateString);
        const timeString = dateString + " 00:00:00 +0800";
        const dateData = new Date(timeString);
        console.log(dateData);
        const classArrayNew = [...classArray].map(object => {
            if( ! object.id ) { // 只有預設空值的Object Element ID，將Object Element賦予id
                return {
                    ...object,
                    id: id,
                    startTime: dateData,
                    endTime: dateData
                }
            } else if( object.id === id ) { // Object Element ID符合目標，更新Object Element
                return {
                    ...object,
                    startTime: dateData,
                    endTime: dateData
                }
            }
            else return object; // Object Element ID不符合目標，不更動Object Element
        });
        setClassArray(classArrayNew);
        let flagObjHasSameId = false;
        for( let object of classArrayNew ) {
            if( object.id === id ) {
                flagObjHasSameId = true;
            }
        }        
        if( ! flagObjHasSameId ) {
            setClassArray(prevArray => [...prevArray, {
                id: id,
                startTime: dateData,
                endTime: dateData
            }]);
        }
        // setClassDate(dateString);
        // timePickerOnChange([], timeString);
        // setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["date"]:timeString});
        // setSendingTime(1);
    };
    useEffect(()=>{console.log(classArray)},[classArray])

    const timePickerOnChange = (timeArray, timeStringArray, id) => {
        console.log(timeArray, timeStringArray, id);
        const startTimeString = `${classDate} ${timeStringArray[0]}:00 +0800`;
        const endTimeString = `${classDate} ${timeStringArray[1]}:00 +0800`;
        console.log(startTimeString, endTimeString);
        const startTimeDate = new Date(startTimeString);
        const endTimeDate = new Date(endTimeString);
        console.log(startTimeDate, endTimeDate);
        const classArrayNew = [...classArray].map(object => {
            if( ! object.id ) { // 只有預設空值的Object Element ID，將Object Element賦予id
                return {
                    ...object,
                    id: id,
                    startTime: startTimeDate,
                    endTime: endTimeDate
                }
            } else if( object.id === id ) { // Object Element ID符合目標，更新Object Element
                return {
                    ...object,
                    startTime: startTimeDate,
                    endTime: endTimeDate
                }
            }
            else return object; // Object Element ID不符合目標，不更動Object Element
        });
        setClassArray(classArrayNew);
        let flagObjHasSameId = false;
        for( let object of classArrayNew ) {
            console.log(object.id);
            console.log(id);
            if( object.id === id ) {
                flagObjHasSameId = true;
            }
        }
        if( ! flagObjHasSameId ) {
            setClassArray(prevArray => [...prevArray, {
                id: id,
                startTime: startTimeDate,
                endTime: endTimeDate
            }]);
        }
        // console.log(timeStringArray[0], timeStringArray[1]);
        // setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["startTime"]:timeStringArray[0], ["endTime"]:timeStringArray[1]});
        // setSendingTime(1);
    };
    useEffect(()=>{
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["locationTime"]:classArray})
    } ,[classArray])

    const checkboxOnChange = (event) => {
        const id = event?.target?.id || "";
        const checked = event?.target?.checked || false;
        const checkElement = { id, checked };
        if( Object.values(checkList[0]).length > 0 ) { // CheckList 有值，取代或新增check資料
            let checkListArray = new Array();
            let hasSameId = false;
            checkListArray = checkList.map((obj) => {
                const objId = obj?.id || "";
                if( objId === id ) {
                    obj.checked = checked; // 取代check資料
                    hasSameId = true;
                }
                return obj;
            });
            if( ! hasSameId ) { // 未比對到同ID的物件，新增check資料
                setCheckList((current) => [...current, checkElement]);
            } else {  // 有比對到同ID的物件，取代原CheckList
                setCheckList(checkListArray);
            }
        } else { // 無值則新增check資料
            let checkListArray = new Array;
            checkListArray.push(checkElement);
            setCheckList(checkListArray);
        }
        let msgLimitIsChecked = false;
        for( let el of checkList ) { // msgLimit前次設定的值
            if( Object.values(el).includes("msgLimit") ) {
                msgLimitIsChecked = el?.checked || false;
            }
        }
        if( ( id === "msgLimit" && checked ) || msgLimitIsChecked ) { // msgLimit事件觸發設定的值或前次設定的值
            setShowInputNumber(true);
        } else {
            setShowInputNumber(false);
        }
    };
    useEffect(() => {
        console.log(checkList);
    }, [checkList]);
    
    /*** 篩選欄位onChange ***/
    // const filterFieldsOnChange = (e) => {
    //     e.preventDefault();
    //     const fieldId = e?.target?.id || "";
    //     const value = e?.target?.value || "";
    //     if( !! fieldId ) {
    //     console.log(fieldId);
    //     console.log(value);
    //     switch(fieldId) {
    //         case "CourseName":
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['name']: value});
    //             break;
    //         case "CourseCategory":
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['location']: value});
    //             break;
    //         case "CourseDescription":
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['date']: value});
    //             break;
    //         case "CoursePriority":
    //             const intValue = parseInt(value);
    //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['time']: intValue});
    //             break;
    //     }
    //     }
    // }
    // useEffect(() => {
    //     console.log(filterFieldsOnChangeState);
    // }, [filterFieldsOnChangeState]);

    const { Search } = Input;
    /*** 搜尋欄位onChange ***/
    const searchOnChange = (event) => {
        const val = event?.target?.value || "";
        // setSearchString(val);
        setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["name"]:val })
    }

    /*** 搜尋按鈕 ***/
    // const onSearch = () => {
    //     requestCourse();
    // }

    /*** 設定Course ***/
    const setCourse = async(e) => {
        e?.preventDefault();
        // setCourseDataObj(filterFieldsOnChangeState); 
        // setLocationTimeArray(filterFieldsOnChangeState?.locationTime||[]);
        // console.log(filterFieldsOnChangeState["name"])
        const url = wpApiURL + "/kidproCourse/addCourse";
        console.log(url);
        // console.log(searchString);
        const response = await fetch(
            url,
            {
                method: 'POST',
                body: JSON.stringify(filterFieldsOnChangeState),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json" 
                },
            }
        )
        // .then((res) => res.json())
        .catch((err) => {
            alert(`課程新增失敗！\n${err}`);
            throw new Error("Add Course failed");
        });
        console.log(response);
        if( response.status === 201 ) {
            alert("課程設定成功！");
            // setCourseSettingSuccess(!courseSettingSuccess);
            setAfterFilterShow({"display":"block"});  // 顯示設定完成後課程
        } else {
            // let responseMsg = response?.message || "";
            // responseMsg = responseMsg.replace(/[\[\]"]/g, '');
            alert(`課程設定失敗！\nError ${response.statusCode}：${response.error} (${JSON.stringify((response.message)).replace(/[\[\]"]/g, ' ')})`);
            console.log(response);
        }
        requestClass();
        initialize();
    }
    /*** 更新Course ***/
    const updateCourse = async(e, pid) => {
        e?.preventDefault();
        const url = wpApiURL + "/kidproCourse/updateManyCourseNameByPid/" + pid;
        console.log(url);
        // console.log(searchString);
        const response = await fetch(
            url,
            {
                method: 'PUT',
                body: JSON.stringify(updateFieldsOnChangeState),
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json" 
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            alert(`課程更新失敗！\n( Product ID : ${pid} )\n${err}`);
            throw new Error("Update Course failed");
        });
        console.log(response);
        if( response.code === 200 ) {
            alert(`課程更新成功！\n( Product ID : ${pid} )`);
            // setCourseSettingSuccess(!courseSettingSuccess);
            // setAfterFilterShow({"display":"block"});  // 顯示篩選後標籤
        } else {
            // let responseMsg = response?.message || "";
            // responseMsg = responseMsg.replace(/[\[\]"]/g, '');
            alert(`課程更新失敗！\n( Product ID : ${pid} )\nError ${response.code}：${response.error} (${response.message})`);
            console.log(response);
        }
        requestClass();
        initialize();
    }
    /*** 刪除Course ***/
    const deleteCourse = async( e, pid, name ) => {
        e?.preventDefault();
        if( window.confirm(`確定要刪除課程【${name}】？ ( 商品ID：${pid} )`) ) {
            const url = wpApiURL + "/kidproCourse/delManyCourseFlagByPid/" + pid;
            console.log(url);
            const data = { "flag": -1 }
            // console.log(searchString);
            const response = await fetch(
                url,
                {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: {
                        "Accept": "*/*",
                        "Content-Type": "application/json"
                    },
                }
            )
            .then((res) => res.json())
            .catch((err) => {
                alert(`課程刪除失敗！\n( Product ID : ${pid} )\n${err}`);
                throw new Error("Delete Course failed");
            });
            console.log(response);
            if( response.code === 200 ) {
                alert(`課程刪除成功！\n( Product ID : ${pid} )`);
                // setCourseSettingSuccess(!courseSettingSuccess);
                // setAfterFilterShow({"display":"block"});  // 顯示篩選後標籤
            } else {
                // let responseMsg = response?.message || "";
                // responseMsg = responseMsg.replace(/[\[\]"]/g, '');
                alert(`課程刪除失敗！\n( Product ID : ${pid} )\nError ${response.statusCode}：${response.error} ( ${response.message} )`);
                console.log(response);
            }
        } else {
            return;
        }
        requestClass();
        initialize();
    }
    /*** 檢視課程 ***/
    const seeCourses = async(e, id, name) => {
        e.preventDefault();
        console.log(id);
        console.log(name);
        const attrObj = {
            key: id, 
            label: name,
        // onClick: ({ item, key, keyPath, domEvent }) => {
        //   console.log(key);
        // }
        };
        // recentMessagesStore(attrObj);
        // const url = apiURL + "/api/message/v1/chat-tags/";
        // console.log(url);
        // console.log(addFieldsOnChangeState);
        // const response = await fetch(
        //   url,
        //   {
        //     method: 'POST',
        //     body: JSON.stringify(addFieldsOnChangeState),
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Accept": "application/json",
        //     },
        //   }
        // )
        // .then((res) => res.json())
        // .catch((err) => {
        //   alert(`標籤新增失敗！\n${err}`);
        //   throw new Error("Add Messages failed");
        // });
        // console.log(JSON.stringify(response));
        // const responseId = response?._id || "";
        // const responseStatus = response?.statusCode || "";
        // if( ! responseId ) {
        //   const responseErrorMsg = response?.message || "";
        //   if( responseStatus === 500 ) {
        //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
        //   } else if( responseStatus === 400 ) {
        //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
        //   } else {
        //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
        //   }
        //   return false;
        // } else {
        //   const name = await response?.name || "";
        //   const category = await response?.category || "";
        //   const description = await response?.description || "";
        //   const priority = await response?.priority || 0;
        //   const data = { _id: responseId, name, category, description, priority };
        //   tagsDataArray.push(data);
        //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
        //   setAddFieldsOnChangeState({}); // 清空欄位
        //   requestMessages(name); // 查詢已新增的標籤
        //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
        // }
    }     
    const courseDataObjTest =
    {
        "pid": "pcc-003",
        "name": "CourseName003",
        "courseIntroduction": "CourseIntro003",
        "price": 1900,
        "teacher": "",
        "maximum": 8,
        "formal": 5,
        "toExperience": 1,
        "compensate": 2,
        "locationTime": [
            {
                "id": 1687146193223,
                "location": "RM002",
                "startTime": "2023-06-28T03:00:00.000Z",
                "endTime": "2023-06-28T03:30:00.000Z"
            },
            {
                "id": 1687146222771,
                "location": "RM003",
                "startTime": "2023-06-29T05:00:00.000Z",
                "endTime": "2023-06-29T05:30:00.000Z"
            }
        ],
        "flag": 1,
        "operator": "maple.snow462"
    }
    const locationTimeArrayTest = [
        {
            "id": 1687146193223,
            "location": "RM002",
            "startTime": "2023-06-28T03:00:00.000Z",
            "endTime": "2023-06-28T03:30:00.000Z"
        },
        {
            "id": 1687146222771,
            "location": "RM003",
            "startTime": "2023-06-29T05:00:00.000Z",
            "endTime": "2023-06-29T05:30:00.000Z"
        }
    ]


    const text = `
        A dog is a type of domesticated animal.
        Known for its loyalty and faithfulness,
        it can be found as a welcome guest in many households across the world.
        `;

    const items2 = [
    {
        key: '1',
        header: 'This is panel header 1',
        content: <p>{text}</p>,
    },
    {
        key: '2',
        header: 'This is panel header 2',
        content: <p>{text}</p>,
    },
    {
        key: '3',
        header: 'This is panel header 3',
        content: <p>{text}</p>,
    },
    ];
    const onChange = (key) => {
        console.log(key);
    };        
    return (
        <Layout id={"WpSetParentChildCourseLayout"}>
            <Form id={"WpSetParentChildCourseForm"} className={style.wpSetParentChildCourseForm} onFinish={()=>{}}>
                <Content className={`${style.wpSetParentChildCourseContent}`} >
                    <section id="WpSetParentChildCourseSection" className={`${style.wpSetParentChildCourseSection} ${style.wpSetParentChildCourseSectionTitle}`}>
                        <h4 className={style.wpSetParentChildCourseH4}>設定【親子課程】</h4>
                    </section>
                    <section id="WpSetParentChildCourseSection" className={`${style.wpSetParentChildCourseSection} ${style.wpSetParentChildCourseSectionCourseIdName}`}>                  
                        <Form.Item className={style.wpSetParentChildCourseFormItem} name="coursePID">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>課程商品ID</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputName}`}>
                                    <Input 
                                        id="coursePID"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputId} ${style.wpSetParentChildCourseFlex2}`} 
                                        placeholder="請輸入商品ID" 
                                        allowClear size="middle" 
                                        onChange={(e)=>fieldsOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item>

                        {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemIntro}`} name="courseIntro">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputIntro}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>課程介紹</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputIntro}`}>
                                    <Input 
                                        id="courseIntro"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputIntro} ${style.wpSetParentChildCourseFlex2}`} 
                                        placeholder="請輸入課程介紹內容" 
                                        allowClear size="middle" 
                                        onChange={(e)=>fieldsOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item>
                        <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemBtn}`} name="coursePrice">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputPrice}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>課程價格</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputPrice}`}>
                                    <InputNumber
                                        id="coursePrice"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputPrice} ${style.wpSetParentChildCourseFlex2}`}
                                        placeholder="請輸入價格"
                                        max={99999}
                                        min={0}
                                        size="small" 
                                        onChange={(e)=>inputNumberPriceOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item>         */}
                        {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemBtn}`} name="classNumber">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputClassNumber}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>課程堂數</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputClassNumber}`}>
                                    <InputNumber
                                        id="classNumber"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputClassNumber} ${style.wpSetParentChildCourseFlex2}`} 
                                        placeholder="請輸入課程堂數" 
                                        defaultValue={8}
                                        size="small" 
                                        onChange={(e)=>inputNumberClassNumberOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item> */}
                        {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemMaximum}`} name="courseMax">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputMaximum}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>人數上限</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputMaximum}`}>
                                    <InputNumber
                                        id="courseMax"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputMaximum} ${style.wpSetParentChildCourseFlex2}`} 
                                        value={filterFieldsOnChangeState?.maximum}
                                        // defaultValue={8}
                                        max={20}
                                        min={0}
                                        size="small" 
                                        onChange={(e)=>inputNumberMaximumOnChange(e)}
                                        disabled
                                    />
                                </div>
                            </Space>
                        </Form.Item>     */}
                        {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemMaximum} ${style.wpSetParentChildCourseFormItemFormal}`} name="courseFormal">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputMaximum} ${style.wpSetParentChildCourseSpaceInputFormal}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>正式生數</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputMaximum} ${style.wpSetParentChildCourseDivInputFormal}`}>
                                    <InputNumber
                                        id="courseFormal"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputMaximum} ${style.wpSetParentChildCourseInputFormal} ${style.wpSetParentChildCourseFlex2}`} 
                                        value={filterFieldsOnChangeState?.formal}
                                        max={ filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.toExperience - filterFieldsOnChangeState?.compensate > 0 ? filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.toExperience - filterFieldsOnChangeState?.compensate : 0 }
                                        min={0}
                                        size="small" 
                                        onChange={(e)=>inputNumberFormalOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item> */}
                        {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemMaximum} ${style.wpSetParentChildCourseFormItemExp}`} name="courseExp">
                            <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputMaximum} ${style.wpSetParentChildCourseSpaceInputExp}`}>
                                <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>體驗生數</h6>
                                <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputMaximum} ${style.wpSetParentChildCourseDivInputExp}`}>
                                    <InputNumber
                                        id="courseExp"
                                        className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputMaximum} ${style.wpSetParentChildCourseInputExp} ${style.wpSetParentChildCourseFlex2}`} 
                                        value={filterFieldsOnChangeState?.toExperience}
                                        max={ filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.compensate > 0 ? filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.compensate : 0 }
                                        min={0}
                                        size="small" 
                                        onChange={(e)=>inputNumberExpOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item> */}
                        <div className={style.wpSetParentChildCourseDivNameAndAddBtn}>
                            {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemMaximum} ${style.wpSetParentChildCourseFormItemCompensate}`} name="courseCompensate">
                                <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputMaximum} ${style.wpSetParentChildCourseSpaceInputCompensate}`}>
                                    <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>補課生數</h6>
                                    <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputMaximum} ${style.wpSetParentChildCourseDivInputCompensate}`}>
                                        <InputNumber
                                            id="courseCompensate"
                                            className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputMaximum} ${style.wpSetParentChildCourseInputCompensate} ${style.wpSetParentChildCourseFlex2}`} 
                                            value={filterFieldsOnChangeState?.compensate}
                                            max={ filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.toExperience > 0 ? filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.toExperience : 0 }
                                            min={0}
                                            size="small" 
                                            onChange={(e)=>inputNumberCompensateOnChange(e)}
                                        />
                                    </div>
                                </Space>
                            </Form.Item> */}
                            <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemName}`} name="courseName">
                                <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputName}`}>
                                    <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>課程名稱</h6>
                                    <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputName}`}>
                                        <Input 
                                            id="courseName"
                                            className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputName} ${style.wpSetParentChildCourseFlex2}`} 
                                            placeholder="請輸入課程名稱" 
                                            allowClear size="middle" 
                                            onChange={(e)=>fieldsOnChange(e)}
                                        />
                                    </div>
                                </Space>
                            </Form.Item>                        
                            {/* <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemAddClass}`} name="addCourse">
                                <Space className={`${style.wpSetParentChildCourseSpaceSendBtn} ${style.wpSetParentChildCourseSpaceSendBtnAdd}`} direction="vertical">
                                    <Button className={style.lineBroadcastSendBtn} size="small" type="primary" // loading={btnLoadings[0]} onClick={(e)=>{
                                                                                                                                            // e.preventDefault();
                                                                                                                                            // let count = addSecCount;
                                                                                                                                            // setAddSecCount(++count);
                                                                                                                                            // console.log(addSecCount);
                                                                                                                                            addClass(e);
                                                                                                                                        }}>
                                        <Space>
                                            新增課堂
                                        </Space>
                                    </Button>    
                                </Space>
                            </Form.Item> */}
                        </div>
                    </section>
                    <section id="WpSetParentChildCourseOuterSectionAddClass" className={`${style.wpSetParentChildCourseOuterSection} ${style.wpSetParentChildCourseOuterSectionAddClass}`}>
                    { addSectionArray.map((idItem, i) => {
                        return(
                            // elNode[1]
                            <section key={idItem.id} id="WpSetParentChildCourseSectionAddClass" className={`${style.wpSetParentChildCourseSection} ${style.wpSetParentChildCourseSectionAddClass}`}>
                                <div id={`class-${idItem.id}`} className={`${style.wpSetParentChildCourseDivClass}`}>
                                    <div className={style.wpSetParentChildCourseDivDeleteOutlined}>
                                        <DeleteOutlined className={style.wpSetParentChildCourseDelIcon} onClick={(e)=>{
                                                                                                            // e.preventDefault();
                                                                                                            // let count = addSecCount;
                                                                                                            // setAddSecCount(--count);
                                                                                                            removeClass(e, idItem.id);
                                                                                                        }}/>
                                    </div>
                                    <Form.Item className={style.wpSetParentChildCourseFormItem} name="classLocation">
                                        <Space className={`${style.wpSetParentChildCourseSpaceInput} ${style.wpSetParentChildCourseSpaceInputName}`}>
                                            <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>上課地點</h6>
                                            <div className={`${style.wpSetParentChildCourseDivInput} ${style.wpSetParentChildCourseDivInputPlace}`}>
                                                <Input 
                                                    id={`classLocation`}
                                                    className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputPlace} ${style.wpSetParentChildCourseFlex2}`} 
                                                    placeholder="請輸入上課地點" 
                                                    allowClear size="middle" 
                                                    onChange={(e)=>fieldsOnChange(e, idItem.id)}
                                                />
                                            </div>
                                        </Space>
                                    </Form.Item>
                                    <Form.Item className={style.wpSetParentChildCourseFormItem} name="classDate" rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}>
                                        <Space className={style.wpSetParentChildCourseSpaceTime}>
                                            <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>上課日期</h6>
                                            <div className={`${style.wpSetParentChildCourseDivTimePicker}`}>
                                                <DatePicker 
                                                    id={`classDate`}
                                                    className={`${style.wpSetParentChildCourseDatePicker} ${style.wpSetParentChildCourseFlex2}`} 
                                                    placeholder={"請選擇上課日期"}
                                                    onChange={(date, dateString) => datePickerOnChange(date, dateString, idItem.id)}
                                                    suffixIcon={null}
                                                />
                                            </div>
                                        </Space>
                                    </Form.Item>
                                    <Form.Item className={style.wpSetParentChildCourseFormItem} name="classTime" rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}>
                                        <Space className={style.wpSetParentChildCourseSpaceTime}>
                                            <h6 className={`${style.wpSetParentChildCourseH6} ${style.wpSetParentChildCourseFlex1}`}>上課時間</h6>
                                            <div className={`${style.wpSetParentChildCourseDivTimePicker}`}>
                                                <TimePicker.RangePicker
                                                    id={`classTime`}
                                                    className={`${style.wpSetParentChildCourseTimePicker}`} 
                                                    placeholder={["課堂開始時間","課堂結束時間"]}
                                                    onChange={(timeArray, timeStringArray) => timePickerOnChange(timeArray, timeStringArray, idItem.id)}
                                                    format={"HH:mm"}
                                                    minuteStep={5}
                                                    suffixIcon={null}
                                                    needsConfirmation={false}
                                                />
                                            </div>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </section>
                        )
                        
                    })}
                    </section>
                    <section id="WpSetParentChildCourseSection" className={`${style.wpSetParentChildCourseSection} ${style.wpSetParentChildCourseBtnSection}`}>
                        <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemSendBtn}`} name="addCourse">
                            <Space className={style.wpSetParentChildCourseSpaceSendBtn} >
                                <Button className={style.lineBroadcastSendBtn} type="primary" 
                                    // loading={btnLoadings[0]} 
                                    onClick={(e) => addClass(e)}>
                                    <Space>
                                        新增課堂
                                    </Space>
                                </Button>    
                            </Space>
                        </Form.Item>                        
                        <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemSendBtn}`} name="setCourse">
                            <Space className={style.wpSetParentChildCourseSpaceSendBtn} >
                                <Button className={style.lineBroadcastSendBtn} type="primary" 
                                    // loading={btnLoadings[1]} 
                                    onClick={(e) => setCourse(e)}>
                                    <Space>
                                        完成設定
                                    </Space>
                                </Button>    
                            </Space>
                        </Form.Item>
                    </section>
                </Content>   
            </Form>
            <Content className={`${style.wpSetParentChildCourseFilterConsultContent} ${style.wpSetParentChildCourseFilterConsultContentQuery}`} style={afterFilterShow}>
                <section id={"WpSetParentChildCourseFilterConsultSearchSec"} className={style.wpSetParentChildCourseFilterConsultSearchSec}>
                    <div className={style.wpSetParentChildCourseFilterConsultTitle}>
                        <h5>已新增課程：</h5>
                    </div>
                    <List
                        className={style.wpSetParentChildCourseFilterConsultList}
                        locale={{}}
                        // dataSource={locationTimeArray}
                        dataSource={courseDataArrayUnique}
                        renderItem={(item, index) => (
                            item?.flag > -1 && Object.keys(courseDataArray[0]).includes('_id') && isEditMode[0] === index && isEditMode[1] ? (       
                                <List.Item>
                                    <List.Item.Meta
                                        className={style.wpSetParentChildCourseFilterConsultListItemMeta}
                                        // avatar={
                                        //     // <Tag className={style.wpSetParentChildCourseFilterConsultTagIcon} color="geekblue">{item.name}</Tag>
                                        //     <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item.name}</span>
                                        // }
                                        title={
                                            <>
                                                <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDiv} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDivUpdate}`}>
                                                    <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDivUpdate}`}>
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程商品ID："}</span>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item?.pid}</span>
                                                            {/* <Input 
                                                                id={`coursePID`}
                                                                className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputId} ${style.wpSetParentChildCourseFlex2}`} 
                                                                placeholder="請輸入課程商品ID" 
                                                                defaultValue={item?.pid}
                                                                allowClear size="middle" 
                                                                onChange={(e)=>updateFieldsOnChange(e, item?.pid)}
                                                            /> */}
                                                        </div>                                            
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程名稱："}</span>
                                                            {/* <span className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionText}`}>{item?.name}</span> */}
                                                            <Input 
                                                                id={`courseName`}
                                                                className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputName} ${style.wpSetParentChildCourseFlex2}`} 
                                                                placeholder="請輸入課程名稱" 
                                                                defaultValue={item?.name}
                                                                allowClear size="middle" 
                                                                onChange={(e)=>updateFieldsOnChange(e, item?.name)}
                                                            />
                                                            {/* <div className={style.wpSetParentChildCourseFilterConsultIconDiv}>
                                                                <EyeOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { seeCourses(e, item._id, item.name) }} />
                                                            </div> */}
                                                            <Space className={`${style.wpSetParentChildCourseSpaceUpdateBtn}`} direction="vertical">
                                                                <Button className={style.lineBroadcastSendBtn} size="small" type="primary" 
                                                                    // loading={btnLoadings[2} 
                                                                    onClick={(e)=>{
                                                                        updateCourse(e, item?.pid);
                                                                    }}>
                                                                    <Space className={style.wpSetParentChildCourseSpaceUpdateBtnText}>
                                                                        儲存更新
                                                                    </Space>
                                                                </Button>    
                                                            </Space>
                                                        </div>
                                                        {/* <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}></span>
                                                        </div> */}
                                                    </div>
                                                    <div className={style.wpSetParentChildCourseFilterConsultIconDiv}>
                                                        <RollbackOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => {setIsEditMode([-1, false]); }} />
                                                        <DeleteOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { deleteCourse(e, item?.pid, item?.name); setIsEditMode([-1, false]); }} />
                                                        {/* <EyeOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { 
                                                            seeCourses(e, item._id, item.name) 
                                                        }} /> */}
                                                    </div>
                                                </div>
                                                {/* <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDivUpdate}`}>
                                                    <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程價格："}</span>
                                                        <InputNumber
                                                            id={`coursePrcie`}
                                                            className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputPrice} ${style.wpSetParentChildCourseInputPriceUpdate} ${style.wpSetParentChildCourseFlex2}`} 
                                                            placeholder="請輸入課程價格" 
                                                            defaultValue={item?.price}
                                                            max={99999}
                                                            min={0}
                                                            size="small" 
                                                            onChange={(e)=>updateFieldsOnChange(e, item?.price)}
                                                        />
                                                    </div>                                                        
                                                    <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv}`}>
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIntro}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程介紹："}</span>
                                                            <Input 
                                                                id={`courseIntroduction`}
                                                                className={`${style.wpSetParentChildCourseInput} ${style.wpSetParentChildCourseInputIntro} ${style.wpSetParentChildCourseInputIntroUpdate} ${style.wpSetParentChildCourseFlex2}`} 
                                                                placeholder="請輸入課程介紹" 
                                                                defaultValue={item?.courseIntroduction}
                                                                allowClear size="middle" 
                                                                onChange={(e)=>updateFieldsOnChange(e, item?.courseIntroduction)}
                                                            />
                                                        </div>                                            
                                                    </div>
                                                </div>                                             */}
                                            </>
                                        }
                                        description={
                                            <Collapse onChange={onChange}>
                                                <Panel className={style.wpSetParentChildCourseFilterConsultCourseDescriptionPanel} header={
                                                    "課表"
                                                    // <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                    //     <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課堂資訊"}</span>
                                                    // </div>   
                                                } key={item?._id}>
                                                    {locationTimeArray.map((item2) => {
                                                        if(item?.pid === item2?.pid) {
                                                            return (
                                                                <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionDiv} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionQueryDiv}`}>                                            
                                                                    <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"地點："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item2?.location}</span>
                                                                    </div>
                                                                    {/* <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"日期："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item2?.locationTime?.date}</span>
                                                                    </div> */}
                                                                    <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"開始時間："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{dayjs(item2?.startTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div>
                                                                    <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"結束時間："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{dayjs(item2?.endTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div>
                                                                </div> 
                                                            )
                                                        } else {
                                                            return (<></>)
                                                        }
                                                    })} 
                                                </Panel>
                                            </Collapse>
                                        }
                                    />
                                </List.Item>
                            ) : item?.flag > -1 && Object.keys(courseDataArray[0]).includes('_id') ? (
                                <List.Item>
                                    <List.Item.Meta
                                        className={style.wpSetParentChildCourseFilterConsultListItemMeta}
                                        // avatar={
                                        //     // <Tag className={style.wpSetParentChildCourseFilterConsultTagIcon} color="geekblue">{item.name}</Tag>
                                        //     <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item.name}</span>
                                        // }
                                        title={
                                            <>
                                                <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDiv}`}>
                                                    <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv}`}>
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程商品ID："}</span><span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item?.pid}</span>
                                                        </div>                                            
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程名稱："}</span><span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item?.name}</span>
                                                            {/* <div className={style.wpSetParentChildCourseFilterConsultIconDiv}>
                                                                <EyeOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { seeCourses(e, item._id, item.name) }} />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className={style.wpSetParentChildCourseFilterConsultIconDiv}>
                                                        <EditOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { 
                                                            setIsEditMode([index, true]);
                                                            // setCourseObj({...courseObj, ['_id']:item?._id, ['location']:item?.location, ['startTime']:new Date(item?.startTime), ['endTime']:new Date(item?.endTime)})
                                                        }} />
                                                        <DeleteOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { deleteCourse(e, item?.pid, item?.name); setIsEditMode([-1, false]); }} />
                                                        {/* <EyeOutlined className={style.wpSetParentChildCourseFilterConsultIcon} onClick={(e) => { 
                                                            seeCourses(e, item._id, item.name) 
                                                        }} /> */}
                                                    </div>   
                                                </div>
                                                {/* <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDiv}`}>
                                                    <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv}`}>
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程價格："}</span><span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item?.price}</span>
                                                        </div>
                                                        <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課程介紹："}</span><span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item?.courseIntroduction}</span>
                                                        </div>                                            
                                                    </div>
                                                </div>                                             */}
                                            </>
                                        }
                                        description={
                                            <Collapse onChange={onChange}>
                                                <Panel className={style.wpSetParentChildCourseFilterConsultCourseDescriptionPanel} header={
                                                    "課表"
                                                    // <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle}`}>
                                                    //     <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionName}>{"課堂資訊"}</span>
                                                    // </div>   
                                                } key={item?._id}>
                                                    {locationTimeArray.map((item2) => {
                                                        if(item?.pid === item2?.pid) {
                                                            return (
                                                                <div className={`${style.wpSetParentChildCourseFilterConsultCourseDescriptionDiv} ${style.wpSetParentChildCourseFilterConsultCourseDescriptionQueryDiv}`}>                                            
                                                                    <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"地點："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item2?.location}</span>
                                                                    </div>
                                                                    {/* <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"日期："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{item2?.locationTime?.date}</span>
                                                                    </div> */}
                                                                    <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"開始時間："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{dayjs(item2?.startTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div>
                                                                    <div className={style.wpSetParentChildCourseFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionLabel}>{"結束時間："}</span>
                                                                        <span className={style.wpSetParentChildCourseFilterConsultCourseDescriptionText}>{dayjs(item2?.endTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div>
                                                                </div> 
                                                            )
                                                        } else {
                                                            return (<></>)
                                                        }
                                                    })} 
                                                </Panel>
                                            </Collapse>
                                        }
                                    />
                                </List.Item>                                    
                            ) : (
                                <></>
                            )
                        )}
                    />
                </section>
                {/* <section id="WpSetParentChildCourseSection" className={style.wpSetParentChildCourseSection}>
                    <Form.Item className={`${style.wpSetParentChildCourseFormItem} ${style.wpSetParentChildCourseFormItemSendBtn}`} name="advance">
                        <Space className={style.wpSetParentChildCourseSpaceSendBtn} direction="vertical">
                            <Button className={style.lineBroadcastSendBtn} size="middle" type="primary" // loading={btnLoadings[0]} onClick={(e) => requestCourse(e)}>
                                <Space>
                                    完成設定
                                </Space>
                            </Button>    
                        </Space>
                    </Form.Item>
                </section> */}
            </Content>                 
        </Layout>
    );
}

export default WpSetParentChildCourse