import React, { useState, useContext, useEffect } from 'react'
import { GlobalUserData } from "../../pages/Customer/Customer";
import { Input, Button } from 'react-chat-elements'
import CryptoJS  from 'crypto-js';

/*** CSS ***/
import style from "./styles/SocketAdmin.module.css";

/*** Import Socket Component ***/
import { GlobalAdminSocket } from './SocketAdminConnect';
import { socketConnect } from 'socket.io-react';

function SocketAdminSendMessage(props) {
    const {globalAdminUserSocket, globalAdminPanelSocket, setGlobalAdminUserSocket, setGlobalAdminPanelSocket, roomId, joinRoom} = props;
    const [globalAdminSocket , setGlobalAdminSocket ] = useContext(GlobalAdminSocket);
    // const globalAdminUserSocket = globalAdminSocket?.adminUserSocket || "";
    // const globalAdminPanelSocket = globalAdminSocket?.adminPanelSocket || "";
    // const isGlobalAdminUserSocket = globalAdminUserSocket?.connected || false;
    // const isGlobalAdminPanelSocket = globalAdminPanelSocket?.connected || false;
    // const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(globalAdminSocket?.globalAdminUserSocket); // 初始值設定正確才能及時觸發主動連線
    // const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(globalAdminSocket?.globalAdminPanelSocket);
    const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] = useState(globalAdminSocket?.globalAdminUserSocket?.connected);
    const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] = useState(globalAdminSocket?.globalAdminPanelSocket?.connected);    
    const globalUserData = useContext(GlobalUserData);
    const [textMsg, setTextMsg] =useState({});

    // /*** 若管理者已取得Room ID而未加入房間，則自動加入房間 ***/
    // const adminJoinRoomPassive = async(roomId) => {
    //     if( !! roomId ) {
    //         console.log(globalAdminUserSocket);
    //         const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
    //         console.log(globalAdminUserSocketConnected);
    //         console.log(roomId);
    //         if( globalAdminUserSocketConnected ) {
    //             globalAdminUserSocket.emit('joinRoom', {'roomId': roomId});
    //             console.log('Administer join room!');
    //         }
    //     }
    // } 
    // useEffect(() => {
    //     adminJoinRoomPassive(roomId);
    // }, []);
    // /*** END 若管理者已取得Room ID而未加入房間，則自動加入房間 ***/    
    
    /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
    const initializeAdminUserAndPanelSocket = async() => {
        console.log(globalAdminSocket);
        let adminUserSocket = null;
        if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
            adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
            console.log(adminUserSocket);
            setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
        }
        let adminPanelSocket = null;
        if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
            adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
            console.log(adminPanelSocket);
            setGlobalAdminPanelSocket(adminPanelSocket);
        }
        await checkAdminUserAndPanelSocketIsConnected(globalAdminUserSocket, globalAdminPanelSocket);
    }
    useEffect(() => {
        initializeAdminUserAndPanelSocket();
    }, []);
    useEffect(() => {
        initializeAdminUserAndPanelSocket();
    }, [globalAdminSocket, roomId]);  
    useEffect(() => {
        console.log('globalAdminUserSocket', globalAdminUserSocket)
        console.log('globalAdminPanelSocket', globalAdminPanelSocket)
    }, [globalAdminUserSocket, globalAdminPanelSocket]);
    /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/    

    /*** 確認 User 及 Panel Socket 連線狀態 ***/
    const checkAdminUserAndPanelSocketIsConnected = async (globalAdminUserSocket, globalAdminPanelSocket) => {
        console.log({ globalAdminUserSocket, globalAdminPanelSocket });
        if ( !! globalAdminUserSocket ) {
            console.log("GlobalAdminUserSocket Connect");
            let adminUserSocketConnected = null;
            if ( typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ) {
                adminUserSocketConnected = globalAdminUserSocket?.connected || null;
                console.log(adminUserSocketConnected);
            }
            console.log(adminUserSocketConnected);
            setGlobalAdminUserSocketConnected(adminUserSocketConnected);
            console.log(globalAdminUserSocketConnected);
        }
        if ( !! globalAdminPanelSocket ) {
            console.log("GlobalAdminPanelSocket Connect");
            console.log(Object.keys(globalAdminPanelSocket).length > 0);
            let adminPanelSocketConnected = null;
            if ( typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ) {
                adminPanelSocketConnected = globalAdminPanelSocket?.connected || null;
                console.log(adminPanelSocketConnected);
            }
            console.log(adminPanelSocketConnected);
            console.log(globalAdminPanelSocketConnected);
            setGlobalAdminPanelSocketConnected(adminPanelSocketConnected);
            console.log(globalAdminPanelSocketConnected);
        }
        console.log({ globalAdminUserSocketConnected, globalAdminPanelSocketConnected });
    };
    useEffect(() => {
        console.log({globalAdminUserSocketConnected, globalAdminPanelSocketConnected});
    }, [globalAdminUserSocketConnected, globalAdminPanelSocketConnected, roomId]);
    /*** END 確認 User 及 Panel Socket連線狀態 ***/

    /*** 取得加密的使用者資料 ***/
    let defaultUserData = null;
    const[userData, setUserData] = useState({});
    const lineAppId = process.env.REACT_APP_LINE_APP_ID;
    const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : "";
    const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";    
    const getCipherUserData = () => {
        if( !! accessToken && !! cpo ) {
            try {
                const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
                // console.log(userDataJson);
                const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
                // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
                const userDataDecode = JSON.parse( userDataUTF8 );
                // setUserData(userDataDecode);

                // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
                // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));
                
                setUserData( userData => ({...userData, userDataDecode}));
                // console.log({userData});
                defaultUserData = userDataDecode;
                // console.log({defaultUserData});
                return userDataDecode;
            } catch (e) {
                console.log(e);
                return false;
            }   
        }   
    }
    useEffect(() => {
        getCipherUserData();
    }, [cpo, roomId])
    /*** END 取得加密的使用者資料 ***/     

    /*** 使用者在訊息欄輸入訊息，將訊息存入State ***/
    const changeFields = (event) => {
        event.preventDefault();
        const val = event?.target?.value || "";
        console.log({ val });
        /* 取得的使用者資料預設格式
            {
                userId: profile.userId,
                userStatus: "【一般使用者】",
                firstName: "",
                lastName: profile.displayName,
                email: "",
                phone: "",
                lineId: profile.userId,
                isLineBind: false,
                avatar: profile.pictureUrl,
                statusMessage: profile.statusMessage
            }
        */
        // const lastName = defaultUserData?.lastName || "";
        // setTypingText({
        //     ...typingText, 
        //     position: "right",
        //     type: "text",
        //     title: lastName,
        //     text: val,  
        // });
        /* socket on data format */
        // {
        //     "type": "text",
        //     "text": "string",
        //     "data": {
        //       "type": "string",
        //       "name": "string",
        //       "uri": "string",
        //       "buffer": {}
        //     }
        // }

        
        // Validate URL
        // httpRegex.test('uibakery.io/contact'); // Returns true
        // httpRegex.test('/uibakery.io'); // Returns false
        // // Extract URL from a string
        // var httpRegexG = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
        // 'You can view more details at uibakery.io or just ping via email.'.match(httpRegexG); // returns ['uibakery.io']     
        if( typeof val === "string" ) {
            console.log("string");
            const uriRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // Photo URL Regex
            const uriRegexGI = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b((?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/i;
            const nameRegexGI = /[^\/]+(?=\/?$)/i;
            const uriExtractArray = val?.match(uriRegexGI) || "";
            const uriExtract = uriExtractArray[0] ?? "";
            const nameMatch = uriExtractArray[1] ?. match(nameRegexGI);
            const name = nameMatch ? nameMatch[0] : "photo-" + new Date();
            console.log(uriRegex.test(val));
            console.log({uriRegex});
            console.log({uriExtract});
            console.log({name});
            if( uriRegex.test(val) || !! uriExtract ) { // 若為URI格式
                if( uriExtract.indexOf('youtube.com') > -1 || uriExtract.indexOf('vimeo.com') > -1 || uriExtract.indexOf('pratikc.wistia.com') > -1 ) { // 影片格式
                    setTextMsg({
                        ...textMsg, 
                        type: "text",
                        text: val,  
                        dataType: "video",
                        dataName: name,
                        dataUri: uriExtract,  
                    });
                } else { // 圖片格式
                    setTextMsg({
                        ...textMsg, 
                        type: "text",
                        text: val,  
                        dataType: "photo",
                        dataName: name,
                        dataUri: uriExtract,  
                    });
                }
            } else {
                setTextMsg({
                    ...textMsg, 
                    // position: "left",
                    type: "text",
                    text: val,  
                });
            }  
            console.log({textMsg});
        }
    } /*** END 管理者在訊息欄輸入訊息，將訊息存入State ***/ 

    /*** 管理者點選送出訊息，將訊息以Socket Emit傳出 ***/
    const adminSendMessage = async(e) => {
        // const userData = getCipherUserData();
        e.preventDefault();
        const sendText = textMsg?.text || "";
        const dataType = textMsg?.dataType || "";
        const dataName = textMsg?.dataName || ""; 
        const dataUri = textMsg?.dataUri || "";
        // if(isGlobalAdminUserSocket) {
        const data = {
            channelType: 'CHAT',
            type: 'text',
            text: sendText,
            data: {
                type: dataType,
                name: dataName,
                uri: dataUri
            }
        }           
        console.log('Admin Emit Message !');
        console.log(data); 
        console.log(globalAdminUserSocket);
        console.log(roomId);
        console.log(joinRoom);
        const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
        // if( globalAdminUserSocketConnected && !! roomId ) {
        //     await globalAdminUserSocket.emit('joinRoom', {'roomId': roomId});
        //     await globalAdminUserSocket.on("joinRoom", (res) => {
        //         console.log(res, 'cs');
        //         console.log('Administer join room!');
        //         globalAdminUserSocket.emit('message', data );
        //     });
        // }
        // globalAdminUserSocket.emit('message', data );
        // } else {
        //     const defaultMsg = {
        //         type: 'text',
        //         text: "【請先連線再送出訊息！】",
        //     }
        //     const defaultMsgJson = JSON.stringify(defaultMsg);
        //     window.localStorage.setItem('defaultMsg', defaultMsgJson);
        // }
        
        // 完成送出訊息後，清空輸入欄位
        setTextMsg({}); 
        document.getElementById("socketAdminSendMsgForm").reset();
    } /*** END 管理者點選送出訊息 ***/

    /*** textMsg State改變時重新渲染 ***/
    useEffect(() => {
        return
    }, [textMsg]);
    /*** END textMsg State改變時重新渲染 ***/

    return (
        <form id="socketAdminSendMsgForm" className={style.socketAdminSendMsgForm}>
            <Input
                id="sendMsg"
                name="sendMsg"
                className={style.socketAdminInput}
                placeholder="請在此輸入訊息..."
                // multiline={true}
                onChange={changeFields}
                value={textMsg.text}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        adminSendMessage(e);
                    }
                }}
                rightButtons={<Button text="送出訊息" title="送出訊息" type="button" onClick={(e) => {
                    adminSendMessage(e);
                }} />} 
            />
        </form>
    )
}

export default socketConnect(SocketAdminSendMessage);