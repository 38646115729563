import React, { useContext, useState, useEffect } from 'react';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import liff from '@line/liff';
import Admin from "../../pages/Admin/Admin";
import UaaLogin from "../../UaaLogin/AppAdminLogin";

// export const GlobalAdminSocket = React.createContext({ adminUserSocket: null, adminPanelSocket: null });
export const GlobalAdminSocket = React.createContext();

function SocketAdminConnect(props) {
  const [roomId, setRoomId] = useState("");
  const loginToken = window.localStorage.getItem("loginToken")
    ? window.localStorage.getItem("loginToken")
    : "";
  const host = process.env.REACT_APP_HOST;
  const isWss = process.env.REACT_APP_ISWSS;
  const [globalAdminSocket, setGlobalAdminSocket] = useState({});
  // const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(null);
  // const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(null);
  // const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] = useState(false);
  // const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] = useState(false);
  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(
    globalAdminSocket?.globalAdminUserSocket || {}
  ); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(
    globalAdminSocket?.globalAdminPanelSocket || {}
  );
  const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] =
    useState(globalAdminSocket?.globalAdminUserSocket?.connected || false);
  const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] =
    useState(globalAdminSocket?.globalAdminPanelSocket?.connected || false);
  // let globalAdminUserSocket = null;
  // let globalAdminPanelSocket = null;
  // let isGlobalAdminUserSocket = false;
  // let isGlobalAdminPanelSocket = false;

  // const [pageLoading, setPageLoading] = useState(false);
  // let pageLoading = false;
  // useEffect(() => {
  //   console.log('setPageLoading');
  //   // setPageLoading(true)
  //   pageLoading = true;
  // }, []);

  /*** 若已連線則跳出 ***/
  useEffect(() => {
    // console.log(pageLoading);
    // if( pageLoading ) {
    //   return;
    // }
    console.log("SocketAdminConnect");
  }, []); /*** END 若已連線則跳出 ***/

  /*** 導向Admin登入頁 ***/
  const redirToAdminLogin = () => {
    const params = new URLSearchParams(window.location.search);
    const refParam = params.get("ref");
    const isFromAdminLogin =
      refParam !== null && refParam.toLowerCase() === "adminlogin";
    if (!loginToken && !isFromAdminLogin) {
      window.location.href = "/AdminLogin?ref=Admin";
    }
  };
  useEffect(() => {
    redirToAdminLogin();
  }, []);
  useEffect(() => {
    redirToAdminLogin();
  }, [loginToken]);
  /*** END 導向Admin登入頁 ***/

  /*** 登入後，Socket才進行連線 ***/
  /* Admin User Event Listening Socket Connection */
  console.log(host);
  const adminUserSocketConnect = async () => {
    const socket = await io.connect(
      `${isWss ? "wss" : "ws"}://${host}/ws/line-chat`,
      {
        transports: ["websocket"],
        auth: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    );
    console.log("Socket Admin Connect", socket);
    setGlobalAdminUserSocket(await socket);
    // globalAdminUserSocket = socket;
    // console.log('GlobalAdminUserSocket', await socket);
    // console.log(await socket.connected);
    // isGlobalAdminUserSocket = await socket?.connected || false;
    socket.on("connect", () => {
      setGlobalAdminUserSocketConnected(true);
    });
    socket.on("connect_failed", () => {
      console.log("Sorry, there seems to be an issue with the connection!");
      setGlobalAdminUserSocketConnected(false);
    });
  };

  /* Admin Panel Event Listening Socket Connection */
  const adminPanelSocketConnect = async () => {
    const socket = await io.connect(
      `${isWss ? "wss" : "ws"}://${host}/ws/cs-panel`,
      {
        transports: ["websocket"],
        auth: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    );
    setGlobalAdminPanelSocket(await socket);
    // globalAdminPanelSocket = socket;
    // console.log('GlobalAdminPanelSocket', await socket);
    socket.on("connect", () => {
      setGlobalAdminPanelSocketConnected(true);
    });
    socket.on("connect_failed", () => {
      console.log("Sorry, there seems to be an issue with the connection!");
      setGlobalAdminPanelSocketConnected(false);
    });
  };

  useEffect(() => {
    const asyncSocketConnect = async () => {
      if (!!loginToken && !!host && !!isWss) {
        await adminUserSocketConnect();
        await adminPanelSocketConnect();
        // if( !! globalAdminUserSocket && !! globalAdminPanelSocket ) {
        //   const globalAdminSocket = {globalAdminUserSocket, globalAdminPanelSocket};
        //   setGlobalAdminSocket(globalAdminSocket);
        // }
      }
    };
    asyncSocketConnect();
    console.log([loginToken, host, isWss]);
  }, [loginToken, host, isWss]);

  useEffect(() => {
    console.log({ globalAdminUserSocket, globalAdminPanelSocket });
    console.log({
      globalAdminUserSocketConnected,
      globalAdminPanelSocketConnected,
    });
    if (!!globalAdminUserSocket && !!globalAdminPanelSocket) {
      const globalAdminSocket = {
        globalAdminUserSocket,
        globalAdminPanelSocket,
      };
      setGlobalAdminSocket(globalAdminSocket);
    }
  }, [globalAdminUserSocket, globalAdminPanelSocket]);

  // useEffect(() => {
  //   console.log(globalAdminSocket);
  // }, [globalAdminSocket]);
  /*** END 登入後，Socket才進行連線 ***/

  return (
    <>
      {/* <GlobalAdminSocket.Provider value={{ adminUserSocket: globalAdminUserSocket, adminPanelSocket: globalAdminPanelSocket }}> */}
      <GlobalAdminSocket.Provider
        value={[globalAdminSocket, setGlobalAdminSocket]}
      >
        <Admin></Admin>
      </GlobalAdminSocket.Provider>
    </>
  );
}

export default SocketAdminConnect