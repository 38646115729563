import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom';
// import ThemeSelector from '../../themes/ThemeSelector';
import io from 'socket.io-client';
import { Dropdown, Button } from "react-chat-elements";
import LiffMessenger from './imgs/liff-messenger.webp'
import style from './styles/Customer.module.css'
import customerLiffPopupStyle from './styles/CustomerLiffPopupChatRoomLogin.module.css'
// import './styles/CustomerLiffPopupChatRoomLogin.css'
import './styles/RceStyle.css'
import PopupBanner from './imgs/PopupBanner.png';
import liff from '@line/liff';
import CustomerLiffLogin from './components/CustomerLiffLogin';
import CryptoJS  from 'crypto-js';

/*** Theme Selector ***/
// import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

/*** Import Login Component ***/
import PopupCustomerLogin from '../../UaaLogin/Components/PopupCustomerLogin'

/*** Import Socket Component ***/
import { GlobalSocketFromChatRoomLogin } from '../../SocketIO/Customer/SocketCustomerConnectChatRoomLogin';
// import SocketCustomerJoinRoom from '../../SocketIO/Customer/SocketCustomerJoinRoom'

/*** Customer Chat Room Component ***/
import CustomerChatRoom from './components/CustomerChatRoom';

/* ANT UI */
import { DisconnectOutlined, LinkOutlined, LogoutOutlined } from '@ant-design/icons';

/*** BootStrap ***/
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css'

/***  UTM ***/
import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from 'react-facebook-pixel'; // FB Pixel

export const GlobalUserData = React.createContext({});

function CustomerChatRoomLogin() {
    let globalUserData = null;
    const [isFromCustomer, setIsFromCustomer] = useState(true);
    const [userData, setUserData] = useState({});
    const [showLiffIcon, setShowLiffIcon] = useState(true);
    const loginToken = !! window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : '';
    const lineBind = window.localStorage.getItem("lineBind") ?? false;
    const frontEndURL = process.env.REACT_APP_FRONTENDURL; // 首頁URL
    const liffLoginUrl = "https://" + frontEndURL + "/CustomerLiffLogin";
    const lineAppId = process.env.REACT_APP_LINE_APP_ID;
    const lineid = process.env.REACT_APP_LINE_APP_ID; // 官網LINE Developer ID
    const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
    let cpo = window.localStorage.getItem( "cpo" ) ? window.localStorage.getItem( "cpo" ) : "";
    const screenHeight = window?.innerHeight||0;
    // const customerLiffPopupStyleModalHeaderHeight = document?.getElementById("customerLiffPopupStyleModalHeader")?.offsetHeight || 0;
    // const customerChatRoomSendSecHeight = document?.getElementById("customerChatRoomSendSec")?.offsetHeight || 0;
    // const customerChatRoomContentSecHeight = screenHeight - customerLiffPopupStyleModalHeaderHeight - customerChatRoomSendSecHeight;
    useEffect(() => {
        console.log(screenHeight);
    },[screenHeight]);
    const thisStyle = `
        .modal-dialog{
            margin: 0;
            width: 100%!important;
            max-width: 100%;
            height: 100%;
            position: initial!important;
        }
        .modal-content{
            margin-bottom: -1px;
            width: 100%!important;
            height: 100%;
            max-height: ${screenHeight}px;
            overflow-y: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            overflow: -moz-scrollbars-none; /* Old Firefox */
            scrollbar-width: none;  /* Firefox */
            border-radius: 0;
        }
        .modal-content::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        #customerChatRoomContentSec{
            min-height: ${0.75*screenHeight}px;
        }
        #customerChatRoomSendSec{
            border-radius: 0;
        }
    `

    /*** Theme Selector ***/
    // const themes = {
    //     Customer: 'public/themes/CustomerLiffPopup.css',
    //     CustomerChatRoomLogin: 'public/themes/CustomerLiffPopupChatRoomLogin.css'
    // };
    
    /*** 清除錯誤的cpo ***/
    useEffect(() => {
        const cpoLength = cpo?.length || 0;
        if( cpoLength < 45 ) {
            window.localStorage.setItem( "cpo", "" );
        }
    }, [cpo]);

    /* 取得SocketAdminConnect的連線狀態 */
    let globalSocketVar = null;
    const globalSocketContext = useContext(GlobalSocketFromChatRoomLogin);
    const [globalSocket, setGlobalSocket] = useState(null);
    const [isGlobalSocketConnected, setIsGlobalSocketConnected] = useState(false);
    const [leaveRoom, setLeaveRoom] = useState(false);
    const liffId = process.env.REACT_APP_LINE_APP_ID;
    // const roomId = process.env.REACT_APP_ROOMID;
    const host = process.env.REACT_APP_HOST;
    const isWss = process.env.REACT_APP_ISWSS;
    const [show, setShow] = useState(false); // 設定是否顯示Login Popup
    const [NOuaabindLine, setNOuaabindLine] = useState(true); // 設定登入頁面line是否綁定uaa，預設true為未綁定

    useEffect(() => {
        // const queryParameters = new URLSearchParams( window.location.search );
        // const lineBind = queryParameters.get( "line_bind" ) ?? "";
        window.localStorage.setItem( 'isFromCustomer', true );
        if( lineBind ) {
            setNOuaabindLine(false);
        } else {
            setNOuaabindLine(true);
        }
    }, []);    
    useEffect(() => {
        if( lineBind ) {
            setNOuaabindLine(false);
        } else {
            setNOuaabindLine(true);
        }
    }, [lineBind]);    
    
    /*** 取得加密的使用者資料 ***/
    const getCipherUserData = () => {
        const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : ""; 
        if( !! accessToken && !! cpo ) {
            try {
                const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
                // console.log(userDataJson);
                const userDataUTF8 = userDataJson?.toString( CryptoJS.enc.Utf8 );
                // console.log(userDataUTF8);
                // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
                const userDataDecode = JSON.parse( userDataUTF8 );
                // console.log(userDataDecode);
                // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
                // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));
                setUserData( userData => ({...userData, userDataDecode}));
                globalUserData = userDataDecode;
            } catch (e) {
                console.log(e);
                return false;
            }                
        }   
    }
    useEffect(() => {
        getCipherUserData();
    }, [cpo, accessToken])
    /*** END 取得加密的使用者資料 ***/    

    /*** 確認Socket是否已連線 ***/
    const checkSocketIsConnected = async() => {
        setInterval(() => {
            let globalSocketDefault = Object.keys(globalSocketContext).length !== 0 ? globalSocketContext : null;
            setGlobalSocket(globalSocketDefault);
            console.log({globalSocketContext});
            console.log({globalSocketDefault});
            console.log({globalSocket});
            if( !! globalSocketDefault ) {
                const globalSocketConnected = globalSocketDefault?.connected || false;
                if( globalSocketConnected ) {
                    setIsGlobalSocketConnected(true);
                    // console.log('setIsGlobalSocketConnected(true)');
                } else {
                    setIsGlobalSocketConnected(false);
                    // console.log('setIsGlobalSocketConnected(false)');
                }
                globalSocketDefault.on("connect", () => {
                    setIsGlobalSocketConnected(true);
                    // console.log('setIsGlobalSocketConnected(true)');
                });  
            }
        }, 10000);
    }
    useEffect(() => {
        checkSocketIsConnected();
    }, []);    

    /*** 重設Socket連線Active ***/
    const resetSocketConnectActive = async(e) => {
        e?.preventDefault();
        globalSocketVar = globalSocketContext;
        let globalSocketDefault = globalSocketVar ?? null;
        setGlobalSocket(globalSocketDefault);
        console.log({globalSocketDefault});
        let isGlobalSocketConnectedTemp = globalSocketVar?.connected || false;
        setIsGlobalSocketConnected(isGlobalSocketConnectedTemp);
        console.log({isGlobalSocketConnectedTemp});
        await socketConnectActive();
        let resetSocketConnectActiveInterval = "";
        // if( ! isGlobalSocketConnectedTemp ) {
        //     resetSocketConnectActiveInterval = setInterval(() => {
        //         resetSocketConnectActive();
        //     }, 1000);
        // } else if( !! resetSocketConnectActiveInterval ) {
        //     clearInterval(resetSocketConnectActiveInterval);
        // }
    }

    /*** 登入後，Socket才進行連線 ***/
    /*** LIFF登入後，Socket才進行連線 ***/
    const socketConnectActive = async (e) => {
        e?.preventDefault();
        const init = await liff.init({
            liffId: liffId,
            permissions: ["profile"]
        })
        .then(async() => {
            const isLoggedIn = liff.isLoggedIn();
            if(isLoggedIn) {
                const accessToken = liff.getAccessToken();
                // console.log('accessToken', accessToken);
                if( !! accessToken ) {
                    /* Socket連線 */
                    const socket = io.connect(`${isWss ? 'wss' : 'ws'}://${host}/ws/line-chat`, {
                        transports: ['websocket'],
                        auth: {
                            LineAccessToken: accessToken,
                            Authorization: `Bearer ${loginToken}`,
                        },
                    }); /* END Socket連線 */
                    console.log(await socket);
                    setGlobalSocket(await socket);
                    socket.on("connect", async() => {
                        // console.log(socket);
                        // console.log(socket.connected);
                        let isGlobalSocketTemp = socket?.connected || false;
                        setIsGlobalSocketConnected(isGlobalSocketTemp);
                        console.log({isGlobalSocketTemp});
                        await userLeaveRoom(await socket);
                        await userJoinRoom(await socket);
                    });                    
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
    } 
    // useEffect(() => {
    //     socketConnectActive();
    // }, [accessToken, roomId, host, isWss, liffId]);
    /*** END LIFF登入後，Socket才進行連線 ***/        

    /*** 使用者加入房間 ***/
    const userJoinRoom = async(socket) => {
        console.log({accessToken});
        // console.log({isGlobalSocketConnected});
        console.log( !! accessToken );
        console.log({socket});
        // socket.on("connect", () => {
        if( !! accessToken ) {
            console.log('userData', userData);
            const messageId = userData?.userId || "";
            console.log({messageId});
            socket.emit('joinRoom', {
                'lineAccessToken': accessToken, 
                // Begin reading the message ID, and if it is not defined, read the last message
                // messageId
            });
            setLeaveRoom(false);
            console.log({leaveRoom});
        }
        // });
    }/*** END 使用者加入房間 ***/ 

    /*** 使用者離開房間 ***/
    const userLeaveRoom = async(socket) => {
        console.log({accessToken});
        // console.log({isGlobalSocketConnected});
        console.log( !! accessToken );
        console.log({socket});
        // socket.on("connect", () => {
        if( !! accessToken ) {
            console.log('userData', userData);
            const messageId = userData?.userId || "";
            console.log({messageId});
            socket.emit('leaveRoom', {
                'lineAccessToken': accessToken, 
                // Begin reading the message ID, and if it is not defined, read the last message
                // messageId
            });
            setLeaveRoom(true);
            console.log({leaveRoom});
        }
        // });
    }/*** END 使用者離開房間 ***/     

    /*** FB Pixel 相關 ***/
    const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
    // Pixel組態設定
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init(FbPixelID, {}, options);
    // ReactPixel.pageView(); // For tracking page view
    const pixelLogout = () => {
        ReactPixel.trackCustom('【登出】', {content_category: 'kidpro_marketing_submit', content_ids: 'logout', content_name: '登出'}); 
    }
    /*** END FB Pixel 相關 ***/    

    /* 登出時，送資料給GA4 */
    const gaLogout = () =>{
        ReactGA.event({
        category: "LogoutCategory",
        action: "logout",
        label: "Logout",
        });
        ReactGA.send({ hitType: "event" });
        // console.log('ReactGA',ReactGA);
    }
    /* 登出 */
    const logout = () => {
        // gaLogout(); // 送出登出事件給GA4
        // pixelLogout(); // 送出登出事件給Pixel
        // 如果FB物件存在，且為登入中，將FB登出
        if( !! window.FB ) {
            window.FB.getLoginStatus(function (response) {
                if(response.status==='connected') {
                window.FB.logout(function (response) {
                    // console.log('handleFBLogout', response);
                });
                }
            });
        }    
        const isInClient = liff.isInClient();
        if( ! isInClient ) { // 以外部伺服器開啟網頁
        // 如果LINE有登入，執行LINE登出
        const isLoggedIn = liff.isLoggedIn();
        if( isLoggedIn ) {
            liff.logout();
            window.localStorage.setItem('LIFF_STORE', ''); 
        }
        const decodedIDToken = "LIFF_STORE:" + lineAppId + ":decodedIDToken";
        const accessToken = "LIFF_STORE:" + lineAppId + ":accessToken";
        const clientId = "LIFF_STORE:" + lineAppId + ":clientId";
        const IDToken = "LIFF_STORE:" + lineAppId + ":IDToken";
        const context = "LIFF_STORE:" + lineAppId + ":context";
        // 清除localStorage
        // localStorage.clear();
        window.localStorage.removeItem('loginToken');
        window.localStorage.removeItem('lat');
        window.localStorage.removeItem(decodedIDToken);
        window.localStorage.removeItem(accessToken);
        window.localStorage.removeItem(clientId);
        window.localStorage.removeItem(IDToken);
        window.localStorage.removeItem(context);
        window.localStorage.removeItem('isFromCustomer');
        window.localStorage.removeItem('lineBind');
        window.localStorage.removeItem('cpo');
        // window.location.assign( window.location.protocol + "//" + window.location.host );
        if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
            // console.log('grantmembership');
            window.location.href = '/GrantMembership';
        } else {
            // console.log('/');
            window.location.href = '/ChatRoomLogin';
        }
        } else { // 以Liff開啟網頁
            window.localStorage.removeItem('username');
            window.localStorage.removeItem('smsVeriStatus');
            window.localStorage.removeItem('phoneNumber');
            window.localStorage.removeItem('url');
            if( window.confirm("此頁面由LINE預設瀏覽器開啟，無法登出LINE。 請問是否要關閉LINE瀏覽器？") ) {
                liff.closeWindow(); // 關閉Liff
            } else {
                if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
                window.location.href = '/GrantMembership';
                } else {
                window.location.href = '/ChatRoomLogin';
                }
            }
            // window.location.href = '/?openExternalBrowser=1';
        }
    }

    /* 由Liff Access Token取得Login Token */
    // const getLoginToken = async(accessToken) => {
    //     // console.log('進入getLoginToken, accessToken: ', accessToken );
    //     const liffLoginData = {
    //         accessToken: accessToken,
    //     }
    //     // console.log({liffLoginData});
    //     await fetch('https://uaa.kid-pro.com/api/uaa/line_liff_login', {
    //         method: 'POST',
    //         body: JSON.stringify(liffLoginData),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //     })
    //     .then((r) => r.json())
    //     .then((result) => {
    //         // console.log('result',result);
    //         if( result.status === 200 ) { // 使用者LINE有綁定官網帳號
    //             window.localStorage.setItem( 'lineRegisterLoginToken', result.data.loginToken ); // 存入UAA Login Token
    //             const BearerToken = 'Bearer ' + result.data.loginToken;
    //             fetch('https://uaa.kid-pro.com/api/uaa/user_pofile', { // 取得使用者資料
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': BearerToken
    //                 }
    //             }) 
    //             .then((r) => r.json() )
    //             .then((result) => {
    //                 /* 回傳資料 
    //                     {
    //                     "status": 200,
    //                     "msg": "OK",
    //                     "data": {
    //                         "userId": "123",
    //                         "firstName": "Ben",
    //                         "lastName": "Jhong",
    //                         "email": "cc@lightup.tw",
    //                         "phone": "+886912345678",
    //                         "lineId": "U7fbxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    //                         "isLineBind": true
    //                     }
    //                     }
    //                 */
    //                 const userId = result?.data?.userId || "";
    //                 const userStatus = "【KidPro會員】"
    //                 const firstName = result?.data?.firstName || "";
    //                 const lastName = result?.data?.lastName || "";
    //                 const email = result?.data?.email || "";
    //                 const phone = result?.data?.phone || "";
    //                 const lineId = result?.data?.lineId || "";
    //                 const isLineBind = result?.data?.isLineBind || "";
    //                 setUserData({ ...userData, [userId]:userId, [userStatus]:userStatus, [firstName]:firstName, [lastName]:lastName, [email]:email, [phone]:phone, [lineId]:lineId, [isLineBind]:isLineBind });
    //                 // console.log('userData_getProfile', userData);
    //                 return userData;
    //             })
    //             .catch((error) => {
    //                 // console.log('error', error);
    //                 alert('伺服器忙線中，請稍候再試！');
    //             });
    //         } 
    //     });
    // }

    /* 取得LIFF使用者資料前，將LIFF初始化 */
    // const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
    const initialGetLiffUserProfile = async() => {
        // console.log('進入initialGetLiffUserProfile');
        try {
            await liff.init({
                liffId: lineid, // Use own liffId
            });
            // getLiffUserProfile().then((getUserData) => {
            //     // console.log({getUserData});
            //     // console.log("getUserData",getUserData);
            //     if( !! getUserData ) {
            //         return getUserData;
            //     } else {
            //         return false;
            //     }
            // });
            let cpo = "";
            const getUserData = await getLiffUserProfile();
            const latdata = window.localStorage.getItem( "lat" ) ? window.localStorage.getItem( "lat" ) : "";
            const decodedIDToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":decodedIDToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":decodedIDToken" ): ""
            const clientId = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":clientId" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":clientId" ) : "";
            const IDToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":IDToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":IDToken" ) : "";
            const context = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":context" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":context" ) : "";
            if( !! accessToken ) {
                cpo = CryptoJS.AES.encrypt( JSON.stringify( getUserData ), accessToken ).toString();
                // cpo = CryptoJS.AES.encrypt( JSON.stringify( getUserData ), accessToken );
                window.localStorage.setItem( "cpo", cpo );
            }            
            return getUserData;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
    useEffect(() => {
        initialGetLiffUserProfile();
    }, []);
    useEffect(() => {
        initialGetLiffUserProfile();
    }, [accessToken]);

    /* 取得LIFF使用者資料 */
    const getLiffUserProfile = async() => {
        // // console.log('進入getLiffUserProfile');
        try {
            const profile = await liff.getProfile();
            /* 由LIFF getProfile取得的使用者資料
            {
                "userId":"U4af4980629...", // 用戶的userId
                "displayName":"Brown", // 用戶的暱稱
                "pictureUrl":"https://profile.line-scdn.net/abcdefghijklmn", // 用戶的大頭貼
                "statusMessage":"Hello, LINE!" // 用戶的個性簽名
            }
            */
            const userDataDefault = {
                userId: profile.userId,
                userStatus: "【一般使用者】",
                firstName: "",
                lastName: profile.displayName,
                email: "",
                phone: "",
                lineId: profile.userId,
                isLineBind: false,
                avatar: profile.pictureUrl,
                statusMessage: profile.statusMessage
            };
            // // console.log('UserData_Default', userDataDefault);
            setUserData(userDataDefault);
            return Promise.resolve(userDataDefault);
            // const accessToken = liff.getAccessToken();
            // // console.log('accessToken', accessToken);
            // if( !! accessToken ) { // 由LINE Access Token取得官網註冊使用者資料
            //     const getUserData = getLoginToken(accessToken) ? getLoginToken(accessToken) : null; // 取得Login Token
            //     // console.log('getUserData', getUserData);
            //     return getUserData;
            // }          
        } catch (err) {
        //   // console.log('error', err);
          return false;
        }
    };    
    
    /*** LINE在子頁面登入後關閉視窗，父頁面顯示LINE登入狀態 ***/
    /* LINE登入後父頁面取得參數，關閉子頁面 */
    const redirParentPage = async() => {
        const getUserData = await initialGetLiffUserProfile();
        // console.log({getUserData});
        // let cpo, lat, dit, at, ci, it, ctxt = "";
        let cpo = "";
        const latdata = window.localStorage.getItem( "lat" ) ? window.localStorage.getItem( "lat" ) : "";
        const decodedIDToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":decodedIDToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":decodedIDToken" ): ""
        const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
        const clientId = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":clientId" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":clientId" ) : "";
        const IDToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":IDToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":IDToken" ) : "";
        const context = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":context" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":context" ) : "";
        if( !! accessToken ) {
            cpo = CryptoJS.AES.encrypt( JSON.stringify( getUserData ), accessToken ).toString();
            window.localStorage.setItem( "cpo", cpo );
        }
        // const shaAccessToken = CryptoJS.SHA256( JSON.stringify( accessToken ) ).toString();;
        // if( !! shaAccessToken ) {
        //     cpo = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( getUserData ), shaAccessToken ).toString() );
        //     lat = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( latdata ), shaAccessToken ).toString() );
        //     dit = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( decodedIDToken ), shaAccessToken ).toString() );
        //     at = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( accessToken ), 'accessToken' ).toString() );
        //     ci = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( clientId ), shaAccessToken ).toString() );
        //     it = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( IDToken ), shaAccessToken ).toString() );
        //     ctxt = encodeURI( CryptoJS.AES.encrypt( JSON.stringify( context ), shaAccessToken ).toString() );
        //     // console.log({cpo,lat,dit,at,ci,it,ctxt});
        // }
        // // console.log({lat,decodedIDToken,accessToken,clientId,IDToken,context});
        // if( !! latdata && !! decodedIDToken && !! accessToken && !! clientId && !! IDToken && !! context ) {
        //     // const windowOpenerLocation = window?.opener?.location || "";
        //     // if( !! windowOpenerLocation ) {
        //         // // window.opener.location.href = "/?cpo=" + cpo + "&lat=" + lat + "&dit=" + dit + "&at=" + at + "&ci=" + ci + "&it=" + it + "&ctxt=" + ctxt; // 父頁面接收子頁面LINE登入參數
        //         // window.opener.location.href = "/?cpobool=true";            
        //         // window.close(); // 子頁面關閉
        //         console.log("window.location.href = /ChatRoomLogin?cpobool=true");
        //         window.location.href = "/ChatRoomLogin?cpobool=true"; 
        //     // }
        // }
    }
    /* 父頁面取得參數後，登入狀態記錄於Local Storage，再讓LIFF進行登入驗證 */
    const parentPageLogin = async() => {
        const queryParameters = new URLSearchParams( window.location.search );
        const cpobool = queryParameters.get( "cpobool" ) ? queryParameters.get( "cpobool" ) : "";
        // const lat = queryParameters.get( "lat" ) ? decodeURI( queryParameters.get( "lat" ) ) : "";
        // const decodedIDToken = queryParameters.get( "dit" ) ? decodeURI( queryParameters.get( "dit" ) ) : "";
        // const accessToken = queryParameters.get( "at" ) ? decodeURI( queryParameters.get( "at" ) ) : "";
        // const clientId = queryParameters.get( "ci" ) ? decodeURI( queryParameters.get( "ci" ) ) : "";
        // const IDToken = queryParameters.get( "it" ) ? decodeURI( queryParameters.get( "it" ) ) : "";
        // const context = queryParameters.get( "ctxt" ) ? decodeURI( queryParameters.get( "ctxt" ) ) : "";
        // // console.log({lat,decodedIDToken,accessToken,clientId,IDToken,context});
        // if( !! lat && !! decodedIDToken && !! accessToken && !! clientId && !! IDToken && !! context ) {
            // const decodedIDTokenKey = "LIFF_STORE:" + lineAppId + ":decodedIDToken";
            // const accessTokenKey = "LIFF_STORE:" + lineAppId + ":accessToken";
            // const clientIdKey = "LIFF_STORE:" + lineAppId + ":clientId";
            // const IDTokenKey = "LIFF_STORE:" + lineAppId + ":IDToken";
            // const contextKey = "LIFF_STORE:" + lineAppId + ":context";
            // // // console.log({latKey,decodedIDTokenKey,accessTokenKey,clientIdKey,IDTokenKey,contextKey});
            // window.localStorage.setItem( "lat", lat );
            // window.localStorage.setItem( decodedIDTokenKey, decodedIDToken );
            // window.localStorage.setItem( accessTokenKey, accessToken );
            // window.localStorage.setItem( clientIdKey, clientId );
            // window.localStorage.setItem( IDTokenKey, IDToken );
            // window.localStorage.setItem( contextKey, context );
            // const getLat = window.localStorage.getItem( "lat" );
            // const getDecodedIDToken = window.localStorage.getItem( decodedIDTokenKey );
            // const getAccessToken = window.localStorage.getItem( accessTokenKey );
            // const getClientId = window.localStorage.getItem( clientIdKey );
            // const getIDToken = window.localStorage.getItem( IDTokenKey );
            // const getContext = window.localStorage.getItem( contextKey );
            // // console.log({getLat,getDecodedIDToken,getAccessToken,getClientId,getIDToken,getContext});

        if( cpobool === "true" ) {
            console.log("window.location.href = /ChatRoomLogin?line_login=true");
            window.location.href = "/ChatRoomLogin?line_login=true";
        }

        // }
    }
    useEffect(() => {
        redirParentPage();
        // parentPageLogin();
    }, []);
    /*** END LINE在子頁面登入後關閉視窗，父頁面顯示LINE登入狀態 ***/

    /*** 驗證使用者是否已登入，未登入顯示登入介面，否則顯示聊天室 ***/
    // const triggerLineLogin = async(isLoggedIn) => {
    //     if( ! isLoggedIn ) { // 只要未登入LINE就顯示LINE登入視窗
    //         setShow(false);
    //         handleCloseCustomerLiffPopup();
    //         // const w = 800;
    //         // const h = 600;
    //         // const left = ( window.screen.width / 2 )-( w / 2 );
    //         // const top = ( window.screen.height / 2 )-( h / 2 );
    //         // window.open( liffLoginUrl, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left );   
    //         window.location.href = liffLoginUrl;
    //     } else if( ! loginToken || ( !! loginToken && NOuaabindLine )) { // 已登入LINE未登入UAA，或已登入LINE及UAA但未綁定LINE
    //         setShow(true);
    //         handleCloseCustomerLiffPopup();
    //     } else if( ! NOuaabindLine ) { // 已登入LINE及UAA且已綁定LINE才顯示聊天室
    //         setShow(false);
    //         if( isGlobalSocketConnected ) {
    //             handleShowCustomerLiffPopup();
    //         } else {
    //             liffPopupCloseAndOpen();
    //         }
    //     }
    // }
    // const syncLineLoginVerifyAndTriggerShow = async() => {
    //     await verifyLineIsLogin();
    //     await triggerLineLogin();
    // }
    // useEffect(() => {
    //     console.log("triggerLineLogin()");
    //     // triggerLineLogin();
    // }, []); 
    /*** END 自動驗證使用者是否已登入，未登入顯示登入介面，否則顯示聊天室 ***/

    /*** Veryfy is Line Login or Not ***/
    const [isLineLogin, setIsLineLogin] = useState(false);
    const verifyLineIsLogin = async() => {
        // const queryParameters = new URLSearchParams( window.location.search );
        // const getLineLogin = queryParameters.get( "line_login" ) ? queryParameters.get( "line_login" ) : "";
        // getCipherUserData(); // 取得加密的使用者資料
        // // console.log({userData});
        // if( getLineLogin === "true" ) {
        //     console.log("getLineLogin === true");
            liff.init({
                liffId: lineid, // Use own liffId
            })
            .then(() => {
                const isLoggedIn = liff.isLoggedIn();
                if( ! isLoggedIn ) { // 未登入LINE
                    setIsLineLogin(false);
                    setShow(false);
                    handleCloseCustomerLiffPopup();
                    window.location.href = liffLoginUrl;
                    // // console.log({isLineLogin});
                    // return isLoggedIn;
                } else { // 有登入LINE才會進行顯示條件判斷
                    setIsLineLogin(true);
                    setShowLiffIcon(false);
                    setTimeout(() => {
                        setShowLiffIcon(true);
                        console.log('loginToken', loginToken);
                        console.log('NOuaabindLine', NOuaabindLine);
                        if( !! loginToken && lineBind ) { // 有登入LINE及UAA且已綁定LINE則自動彈出聊天室
                            setShow(false);
                            if( isGlobalSocketConnected ) {
                                handleShowCustomerLiffPopup();
                            } else {
                                liffPopupCloseAndOpen();
                            }
                            console.log('彈出聊天室');
                        } else if( ! loginToken || ( !! loginToken && ! lineBind ) ) { // 未登入或未綁定LINE則自動彈出綁定視窗
                            setShow(true);
                            console.log('彈出登入介面');
                            handleCloseCustomerLiffPopup();
                        }
                    }, '3000');
                    // // console.log("isLineLogin",isLineLogin);
                    // return isLoggedIn;
                }
            })
            .catch((err) => {
                console.log(err);
                // return false;
            });
        // }
    }
    useEffect(() => {
        verifyLineIsLogin();
    }, []);
    /*** END Veryfy Is Line Login or Not ***/

    /*** LIFF Messenger Popup ***/
    const [showCustomerLiffPopup, setShowCustomerLiffPopup] = useState(false); // 是否顯示Liff Popup
    const handleCloseCustomerLiffPopup = async() => setShowCustomerLiffPopup(false); // 不顯示Liff Popup
    const handleShowCustomerLiffPopup = async() => setShowCustomerLiffPopup(true); // 顯示Liff Popup
    const [LoginToken, setLoginToken] = useState(''); // 設定Login Token
    const CustomerLiffPopup = (props) => {
        const {globalSocket} = props;
        // const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
        /* UTM */
        const pixelLineLogin = () => { // FB Pixel
            ReactPixel.trackCustom('【LINE登入】', {content_category: 'kidpro_marketing_submit', content_ids: 'line_login', content_name: 'LINE登入'}); 
        }
        const gaLineLogin = () =>{ // GA4
            ReactGA.event({
            category: "kidpro_marketing_submit",
            action: "line_login",
            label: "LINE登入",
            });
            ReactGA.send({ hitType: "event" });
        } /* END UTM */
        return (
            <>
                <Modal
                    id="Customer"
                    show={showCustomerLiffPopup}
                    onHide={handleCloseCustomerLiffPopup}
                    // backdrop="static"
                    keyboard={false}
                    dialogClassName={customerLiffPopupStyle.modalContainer}
                    centered
                >
                    <Col className={customerLiffPopupStyle.modal}>
                    {/* <div className={customerLiffPopupStyle.modalClose} onClick={(event) => {handleCloseCustomerLiffPopup();}}>x</div> */}
                        <div id={"customerLiffPopupStyleModalHeader"} className={customerLiffPopupStyle.modalHeader} >
                            {/* <Modal.Title className={customerLiffPopupStyle.modalTitle}></Modal.Title> */}
                            {/* <div className={customerLiffPopupStyle.modalBanner}>
                                <img src={PopupBanner}></img>
                            </div> */}
                            <div className={customerLiffPopupStyle.modalHeaderText}>
                                <h5>聯絡客服</h5>
                            </div>
                            <div style={{'display':'none'}}>
                            {/* <div style={{'color':'#FFF'}}> */}
                                {JSON.stringify(isGlobalSocketConnected)} {JSON.stringify( ! leaveRoom )} {JSON.stringify(isGlobalSocketConnected && ! leaveRoom)}
                            </div>
                            { isGlobalSocketConnected && ! leaveRoom ? (
                                <div className={style.btnDiv}>
                                    <Button 
                                        className={style.btnLink}
                                        text = {"已連線"} 
                                        onClick = {async(e) => {
                                            if( !! accessToken ) {
                                                // stopRunningUseEffect(e);
                                                // resetSocketConnectActive(e);
                                                await userLeaveRoom(globalSocket);
                                                await userJoinRoom(globalSocket);
                                                // setIsGlobalUserSocket(false);
                                                // setIsGlobalPanelSocket(false);
                                            } else {
                                                alert("連線參數錯誤，請確認是否已登入！");
                                            }
                                        }}
                                        backgroundColor = {'#61c56d'} 
                                        title ="重新連線" 
                                        icon = {{
                                            float:'left',
                                            size:15,
                                            component:<LinkOutlined />
                                        }}
                                    />
                                    <Button 
                                        className={style.btnLogout}
                                        text = {"登出"} 
                                        onClick = {(e) => {
                                            if( !! accessToken ) {
                                                logout(e);
                                            } else {
                                                alert("您已登出聊天室！");
                                            }
                                        }}
                                        backgroundColor = {'#61c56d'} 
                                        title ="登出聊天室" 
                                        icon = {{
                                            float:'left',
                                            size:15,
                                            component:<LogoutOutlined />
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className={style.btnDiv}>
                                    <Button
                                        className={style.btnUnlink}
                                        text = {"未連線"} 
                                        onClick = {async(e) => {
                                            if( !! accessToken && !! host && !! isWss ) {
                                                // startRunningUseEffect(e);
                                                // resetSocketConnectActive(e);
                                                await userLeaveRoom(globalSocket);
                                                await userJoinRoom(globalSocket);
                                                // adminUserSocketConnectActive(e);
                                                // adminPanelSocketConnectActive(e);
                                            } else {
                                                alert("連線參數錯誤，請確認是否已登入！");
                                            }
                                        }}
                                        backgroundColor = {'rgb(200 100 100)'} 
                                        title ="重新連線" 
                                        icon = {{
                                            float:'left',
                                            size:15,
                                            component:<DisconnectOutlined />
                                        }}
                                    />
                                    <Button 
                                        className={style.btnLogout}
                                        text = {"登出"} 
                                        onClick = {(e) => {
                                            if( !! accessToken ) {
                                                logout(e);
                                            } else {
                                                alert("您已登出聊天室！");
                                            }
                                        }}
                                        backgroundColor = {'#61c56d'} 
                                        title ="登出聊天室" 
                                        icon = {{
                                            float:'left',
                                            size:15,
                                            component:<LogoutOutlined />
                                        }}
                                    />
                                </div>
                            )}                            
                        </div>
                        <Modal.Body className={customerLiffPopupStyle.modalBody} >
                            <CustomerChatRoom globalSocket={globalSocket}></CustomerChatRoom>
                        </Modal.Body>
                    </Col>
                </Modal>
            </>
        );
    }  /*** END LIFF Messenger Popup ***/ 

    /*** Liff popup 先關閉再彈出 ***/
    const liffPopupCloseAndOpen = async() => {
        await handleShowCustomerLiffPopup();
        await handleCloseCustomerLiffPopup();
        await handleShowCustomerLiffPopup();
    }

    return (
        <>
            <style jsx>{thisStyle}</style>
            <div>
                {showLiffIcon ? (
                    <img className={style.liffMessenger} src={LiffMessenger}></img>
                ) : (
                    <></>
                )}
            </div>
            <GlobalUserData.Provider value={globalUserData}>
                {/* <ThemeSwitcherProvider defaultTheme="CustomerChatRoomLogin" themeMap={themes}> */}
                    <CustomerLiffPopup globalSocket={globalSocketContext}></CustomerLiffPopup>
                {/* </ThemeSwitcherProvider> */}
            </GlobalUserData.Provider>
            <PopupCustomerLogin isFromCustomer={isFromCustomer} setShow={setShow} show={show} NOuaabindLine={NOuaabindLine} setNOuaabindLine={setNOuaabindLine}></PopupCustomerLogin>
            {/* <div>{JSON.stringify(NOuaabindLine)}</div> */}
        </>
    )
}

export default CustomerChatRoomLogin