import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Col, Container, Form, Row } from "react-bootstrap"
import style from './styles/LoginIndex.module.css'
import KidProLoginMark from './imgs/KidProLoginMark.svg'
// import { sendRegisterVeriLink } from '../components/ModuleSendEmail'
import { sha256 } from 'js-sha256'
import axios from 'axios'
import LoadingSpinner from '../../Components/LoadingSpinner' // 引入loading 轉圈圈元件
import liff from '@line/liff'; // liff SDK
import nodeRSA from "node-rsa"; // RSA加密元件
import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4


function LoginIndex(props) {

 const {setSheet}=props;
 const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
 const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
 
  const emailRef = useRef(null); // Email參照欄位
  const smscode = useRef(null); // 簡訊驗證碼參照欄位
  const sendSMSText = useRef(null); // 發送驗證碼按鈕文字

  const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
  const [myform, setMyform] = useState({}); // 設定表單資料
  const [LoginWay, setLoginWay] = useState(false); // 設定進入電子郵件表單or 電話登入表單
  const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
  const [userStatus, setUserStatus] = useState('login'); // 使用者要註冊或登入
  // const [smsveristatus, setsmsveristatus] = useState(false); // 檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(改成上層傳入)
  const [gotoSetPassword, setGotoSetPassword] = useState(false); // 點擊下一步觸發設定密碼表單，前往設定密碼
  const [passwordVeriStatus, setPasswordVeriStatus] = useState(true); // 檢查使用者輸入的密碼與確認密碼，以啟用下一步按鈕
  const [gotoSetEmail, setGotoSetEmail] = useState(false); // 點擊下一步觸發設定Email表單，前往設定註冊Email
  const [LineResponse, setLineResponse] = useState(false); // 設定line登入成功狀態

  /***** 註冊功能State *****/
  const [hasSendSMS, setHasSendSMS] = useState(false); // 檢查是否已發送驗證碼 
  const [verifySMSBtn, setVerifySMSBtn] = useState(true); // 驗證簡訊按鈕生效
  const [showRepassword, setShowRepassword] = useState(false); // 確認密碼是否顯示
  const [dataLose, setDataLose] = useState(false); // 設定資料是否遺失

  /* 註冊時，送資料給GA4 */
  const gaRegister = () =>{
    ReactGA.event( 'sign_up', {
      method: '一般註冊'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaRegister' );
  }
  /* LINE註冊時，送資料給GA4 */
  const gaLineRegister = () =>{
    ReactGA.event( 'sign_up', {
      method: 'LINE註冊'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaLineRegister' );
  }

  /* 檢查使用者是否已輸入過Email，並設定預設值 */
  const storagePhoneNumber = !! window.localStorage.getItem('registerEmail') ? window.localStorage.getItem('registerEmail') : '';
  window.localStorage.setItem( 'registerEmail', storagePhoneNumber ); 
  let regSucTimeoutID = ''; // 3秒後導向註冊成功計時器ID
  
  /******************** 表單註冊Fuction *********************/
  /* 時時記錄表單欄位值 */
  const changeFields = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerEmail: '' });
    const id = event.target.id;
    const val = event.target.value;
    // // console.log({ id, val });
    setMyform({ ...myform, [id]: val });
    setRegxpError('');
    // 檢驗手機是否已填寫
    if( id === 'registerEmail' && val.length > 0 ) {
        window.localStorage.setItem( 'registerEmail', val );
    } else if( id === 'registerEmail' && val.length === 0 ) {
        window.localStorage.setItem( 'registerEmail', '' );
    }
  }

  /* 清除殘留於表單的密碼 */
  const clearMyformRegisterData = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerRepassword: '' });
    setMyform({ ...myform, registerEmail: '' });
  }

  /* Email格式驗證 */
  const verifyEmailFormat = () => {
    // 設定Email驗證格式
    let emailRegxp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    // Email驗證結果
    let emailRegxpResult = emailRegxp.test( myform.email ); 
    // 若表單有自動填入值，則使用自動填入值驗證
    if( !! emailRef.current ) {
      emailRegxpResult = emailRegxp.test( emailRef.current.value ); 
    }
    // 驗證失敗顯示格式錯誤訊息
    if( LoginWay === false && emailRegxpResult === false ) {
      setIsLoading(false);
      setRegxpError('請填寫正確Email資訊')
      return false;
    }
    return true;
  }

  /* 儲存待傳出的資料 */
  // const setPassword = ( event ) => {

  //   event.preventDefault();

  //   let pkeyID = '';
  //   let pkey = '';

  //   fetch('https://uaa.kid-pro.com/api/uaa/auth/public_key', {
  //     method: 'GET',
  //     // body: JSON.stringify(data),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // 'Access-Control-Allow-Origin': '*',
  //       // 'Authorization': BearerToken
  //     },
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     // // console.log('result', result);
  //     if ( result.statusCode === 200 ) {
  //       pkeyID = result.data.id;
  //       pkey = result.data.key;
  //       // console.log('pkeyID', pkeyID);
  //       // console.log('pkey', pkey);
  //       if( passwordVeriStatus ) {
  //         if( !! passwordRef.current ) {
  //           if( !! pkey ) {
  //             // 加密密碼
  //             const cipher = new nodeRSA({ b: 512 }); // 加密物件
              
  //             cipher.importKey(pkey, "pkcs1-public"); // 公鑰放入加密物件
  //             const text = {
  //               "email": "saqwxz06@gmail.com",
  //               "phone": "+886978076260",
  //               "password": "string",
  //               "redirectUrl": "redirectUrl"
  //             };
  //             const encrypted = cipher.encrypt(JSON.stringify(text), "base64");
  //             // console.log("encrypted: ", encrypted);                
  //             // 加密密碼(舊)
  //             let pwd = passwordRef.current.value;
  //             // console.log('pwd222', pwd);
  //             // let encryptedText = encryptRsa.encryptStringWithRsaPublicKey({ text: pwd, pkey });
  //             let pkeytest = '-----BEGIN RSA PUBLIC KEY-----MIIBCgKCAQEAu2LuNnUKl3In6z5ev/BA7WK/fnGJXy6DV6FtkjLxTC9wPeA9RvjJEVGKqABHgPNe/uUyHltsX3WF3IwQCIIB5iw+9soJEwjJ6pn2SP2TXX6YHLG/bcfOpBKxvmMYjsJmb6+kbE+OOiXz5WQAvnmsCJlH5qSrma0BE3LWUtQkC131sz9+iEVIHVk5ow5XRacOYhZNpeHdHc6jdj3EzPa95QGBW1YFxGCt+pHjLjGuUhsj5x4tVW+ZdITXEqvmBcniZ7dSibS8nW0ydSt7OtNrmdvxOSkKIsbTIVUFjzxU6DyrquuS2oOJ2+/Poxqi2EP634OrBSQ/otSydI5zdi58kwIDAQAB-----END RSA PUBLIC KEY-----';
  //             let encryptedText = encryptRsa.encryptStringWithRsaPublicKey({ text: '1qaz2wsx', pkeytest });
  //             // console.log('encryptedText', encryptedText);
  //             if( !! encryptedText ) {
  //               window.localStorage.setItem( 'vup', encryptedText );
  //             } else {
  //               setRegxpError('錯誤，密碼加密失敗！');
  //             }
  //           } else {
  //             setRegxpError('錯誤，缺少金鑰，無法加密此密碼！');
  //           }
  //         } else {
  //           setRegxpError('錯誤，密碼無法送出！');
  //         }
  //       } else {
  //         setRegxpError('密碼未通過驗證！');
  //       }
  //     } else {
  //       setRegxpError('伺服器忙線中，請稍候再試！');
  //       // console.log( 'result.status!==200');
  //     }
  //   })
  //   .catch((error) => {
  //     setRegxpError('伺服器忙線中，請稍候再試！');
  //     // console.log(error);
  //   }); // END Fetch public_key
  // }

  /* 加密資料 */
  const cipherData = ( data, pkey ) => {
    let encryptData = null;
    const cipher = new nodeRSA({ b: 512 }); // 加密物件
    cipher.importKey( pkey, "pkcs1-public" ); // 公鑰放入加密物件
    encryptData = cipher.encrypt( JSON.stringify(data), "base64" );
    if( !! encryptData ) {
      return encryptData;
    }
    return false;
  }

  /* 解密資料 */
  const decipherData = ( data ) => {
    let tder = null;
    let rtder = null;
    tder = window.localStorage.getItem('tder');
    rtder = '-----BEGIN RSA PRIVATE KEY-----' + tder + '-----END RSA PRIVATE KEY-----'; // 解密密碼金鑰
    const decipher = new nodeRSA( rtder, "pkcs1" ); // 金鑰放入解密物件
    const decryptData = JSON.parse( decipher.decrypt(data, "utf8") ); // 進行解密
    // console.log( "decryptData: ", decryptData );
    const returnData = { skey: rtder, decData: decryptData };
    if( !! tder || !! rtder ) {
      return returnData;
    }
    return false;
  }
  
  /* 清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if(isLoggedIn){
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
    window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
        window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
        });
        }
    });
    }
    localStorage.clear();
  }

  /* 進行註冊 */
  const register = ( event ) => {
    event.preventDefault();
    setRegxpError(''); // 清空錯誤訊息 
    setIsLoading(true);

    let registerPhone, registerEmail, registerLineToken, registerPassword, decRegisterPassword, redirectUrl, registerData = null;
    registerPhone = window.localStorage.getItem( 'phN' );
    registerEmail = emailRef.current.value;
    registerLineToken = window.localStorage.getItem('lat');
    if( ! registerLineToken ) {
      registerLineToken = undefined;
    }
    registerPassword = window.localStorage.getItem( 'vup' );
    redirectUrl = window.location.protocol + '/' + window.location.host + '?register=true';


    // 解密密碼
    if( !! registerPassword ) {
      let decipherObj = decipherData(registerPassword);
      decRegisterPassword = decipherObj ? decipherObj.decData : ''; 
    }

    if( !! registerPhone && !! decRegisterPassword && !! registerPassword && !! registerEmail ) {
      registerData = {
        phone: registerPhone,
        email: registerEmail,
        password: decRegisterPassword,
        checkPassword: decRegisterPassword,
        redirectUrl: redirectUrl,
      }
      // console.log('registerData2', registerData);
    } else if ( !! registerPhone && !! decRegisterPassword && !! registerPassword && ! registerEmail ) {
      setRegxpError('請填寫註冊Email！');
      setIsLoading(false);
      return false;
    } else {
      setRegxpError('填寫資料不齊全，請重新註冊！');
      setIsLoading(false);
      setDataLose(true);
      return false;
    }

    // Fetch取得公鑰
    let pkeyID = '';
    let pkey = '';
    fetch('https://uaa.kid-pro.com/api/uaa/auth/public_key', {
      method: 'GET',
      // body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Authorization': BearerToken
      },
    })
    .then((r) => r.json())
    .then((result) => {
      // console.log('result', result);
      if ( result.statusCode === 200 ) {
        pkeyID = result.data.id;
        pkey = result.data.key;
        // console.log('pkeyID', pkeyID);
        // console.log('pkey', pkey);
          if( !! registerData ) {
            if( !! pkey ) {
              // 加密資料
              let cipherRegisterData, cipherRegisterDataJSON = null;
              cipherRegisterData = { data: cipherData( registerData, pkey ) };
              cipherRegisterDataJSON = JSON.stringify(cipherRegisterData)
              // console.log('cipherRegisterData',cipherRegisterData);
              // console.log('cipherRegisterDataJSON',JSON.stringify(cipherRegisterData));
              if( !! cipherRegisterData ) { // 若資料已加密，則進行註冊
                // Fetch進行註冊
                // fetch('https://uaa.kid-pro.com/api/uaa/register', { // 舊版本註冊
                console.log('registerLineToken',typeof(registerLineToken));
                const headers = {
                  'Content-Type': 'application/json',
                  'x-crypt-key-id': pkeyID,
                }
                if( registerLineToken !== undefined ) {
                  headers['x-line-access-token'] = registerLineToken;
                }
                // console.log('headers',headers);
                // 'x-line-access-token': registerLineToken
                fetch('https://uaa.kid-pro.com/api/uaa/register_crypto', {
                  method: 'POST',
                  body: cipherRegisterDataJSON,
                  headers,
                })
                .then((r) => r.json())
                .then((result) => {
                  // console.log('api/uaa/register/result', result);
                  if ( result.statusCode === 201 || result.statusCode === 500 ) {
                    if( window.localStorage.getItem('lineRegister') === 'true' ) {
                      gaLineRegister(); // 送出Line註冊成功事件給GA4
                    } else {
                      gaRegister(); // 送出註冊成功事件給GA4
                    }
                    // alert('註冊成功！');
                    setIsLoading(false);
                    setRegxpError('註冊成功，請至「'+ registerEmail +'」收取您的驗證信，並點選驗證連結進行驗證！');
                    // console.log('回到registerSuccess');
                    asyncRedirTimer();
                  } else if ( result.statusCode === 400 && result.error == 'wordpress request failed.' ) {
                    // alert('錯誤的Email/手機格式！');
                    setRegxpError('Email或手機號碼錯誤，無法進行註冊！');
                    setIsLoading(false);
                  } else if ( result.statusCode === 403 ) {
                    // alert('此Email或手機號碼無法註冊！');
                    setRegxpError('此Email或手機號碼無法註冊！');
                    setIsLoading(false);
                  } else if ( result.statusCode === 409 || result.error == 'registration-error-email-exists' || result.statusCode === 429 ) {
                    // alert('您的Email或手機號碼已被註冊！');
                    setRegxpError('您的Email或手機號碼已被註冊！');
                    setIsLoading(false);
                  } else {
                    // alert('伺服器忙線中，請稍候再試！');
                    setIsLoading(false);
                    setRegxpError('註冊失敗（Email/手機格式錯誤，或LINE資料無法進行解析）！');
                  }
                })
                .catch((error) => {
                  // alert('伺服器忙線中！');
                  setRegxpError('伺服器忙線中，請稍候再試！');
                  // console.log(error);
                  setIsLoading(false);
                }); // END Fetch進行註冊
              } else {
                setRegxpError('錯誤，資料加密失敗！');
              }
            } else {
              setRegxpError('錯誤，缺少金鑰，無法加密資料！');
            }
          } else {
            setRegxpError('錯誤，未能取得表單資料！');
          }
      } else {
        setRegxpError('伺服器忙線中，請稍候再試！');
        // console.log( 'result.status!==200');
      }
    })
    .catch((error) => {
      setRegxpError('伺服器忙線中，請稍候再試！');
      // console.log(error);
    }); // END Fetch取得公鑰


  }


  /* 依序執行計時器及清除計時器 */
  const asyncRedirTimer = async () => {
    const promiseRedirRegSuc = new Promise((resolve, reject) => {
      regSucTimeoutID = setTimeout( () => {
        setSheet('registerSuccess');
      }, 3000 ); 
      // reject(regSucTimeoutID);
      
    });
    // 執行失敗清除計時器
    promiseRedirRegSuc.catch((error) => {
      clearTimeout( regSucTimeoutID ); // 清空導向註冊成功計時器ID
      
      // console.log(error);
    });
  }

  /* 寄送Email驗證信 */
  const sendVerifyEmail = (event) => {
    event.preventDefault();
    // 若Email格式正確，寄送Email驗證信
    if( !! verifyEmailFormat() ) {
      // POST資料
      let emailData = emailRef.current.value;
      let urlData = window.location.protocol + "//" + window.location.host;
      let data = {
        mail: emailData,
        redirectUrl: urlData
      }
      // 寄送Email驗證信
      fetch('https://uaa.kid-pro.com/api/uaa/message/send_mail_verification', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((r) => r.json())
      .then((result) => {
          // console.log('result', result);
        if(result.statusCode === 200){
          // console.log('成功 200'); 
          setRegxpError('驗證信已送出！');
        }else if(result.statusCode === 400){
          // console.log('失敗，錯誤代碼 400')
          setRegxpError('此Email已被註冊！');
          // setNOuaabindLine(false);
          // show 登入 
        }else if(result.statusCode === 409){
          // console.log('失敗，錯誤代碼 409');
          setRegxpError('此Email已被註冊！');
        }else{
          // console.log('驗證信寄送失敗！');
          setRegxpError('驗證信寄送失敗！');
        }
      })
      .catch((error)=>{
        // console.log('Error: 伺服器忙線中，請稍候再試！');
        setRegxpError('伺服器忙線中，請稍候再試！');
      })
    }
  }
  /******************** END 表單註冊Fuction *********************/  

  return (
    <>
    <Row>
        <Col>
            <Form className={style.form}>
                <Col className="mb-4">
                    <div className={style.KidProLoginTitleBox}>
                        <span className={style.KidProLoginTitle}>註冊 新會員</span>
                    </div>
                </Col>
                <Col>
                <Form.Group className="mb-4">
                    <Form.Label className={style.pdsLableEmail}>電子郵件</Form.Label>
                    {/* { myform.registerEmail === myform.registerRepassword ? ( */}
                        {/* <Form.Control type="email" id="registerEmail" name='registerEmail' onChange={changeFields} value='' required/>       */}
                    {/* ) : ( */}   
                        { !! window.localStorage.getItem( 'registerEmail' ) ?
                            ( 
                                <>
                                <input className={style.inputinfiled} type="email" id="registerEmail" name='registerEmail' pattern="^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$" maxlength="50" onChange={changeFields} ref={emailRef} value={window.localStorage.getItem('registerEmail')} placeholder='範例：name@gmail.com' required/>
                                </>
                            )
                            :
                            ( 
                                <>
                                <input className={style.inputinfiled} type="email" id="registerEmail" name='registerEmail' pattern="^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$" maxlength="50" onChange={changeFields} ref={emailRef} value={myform.email} placeholder='範例：name@gmail.com' required/>
                                </>
                            )
                        }   
                    {/* ) }   */}
                    </Form.Group>
                </Col> 
                <Col>
                    <div className={style.RegxpErrorSec}>
                        <p className={style.RegxpError}>{RegxpError}</p>
                    </div>
                </Col>
                <Col className="mb-4">
                {isLoading?(<LoadingSpinner></LoadingSpinner>):''}
                      <div className={style.loginbuttosec}>
                        { ! dataLose ? (
                          <button type="submit" className={style.nextStepButton} onClick={(event)=>{
                            //</div>sendVerifyEmail(event);
                            register(event);
                          }}>寄送驗證信</button>
                        ) : (
                          <button type="submit" className={style.nextStepButton} onClick={(event)=>{
                            setSheet('register');
                            setLoginWay( true );
                            setsmsveristatus(false);
                            setHasSendSMS(false);
                            setShowRepassword(false);
                            setPasswordVeriStatus( false );
                            setRegxpError( '' ); 
                            clearStorage(event);
                          }}>返回LINE註冊</button>
                        )}
                      </div>        
                {/* { passwordVeriStatus ? (
                    <>
                       {isLoading?(<LoadingSpinner></LoadingSpinner>):''}
                      <div className={style.loginbuttosec}>
                        <button type="button" className={style.nextStepButton} onClick={(event)=>{
                          //</div>sendVerifyEmail(event);
                          register(event);
                        }}>寄送驗證信</button>
                      </div>        
                    </>
                        
                ) : (
                    <div className={style.loginbuttosec}>
                        <button type="button" className={style.nextStepButton} disabled>寄送驗證信</button>
                    </div>
                ) } */}
                </Col>  
                <Col>
                    <div className={style.registerEmailTipSec}>
                        <p className={style.registerEmailTip}>請至Email信箱收取驗證信，並點選信件中的驗證連結以啟用您的帳號！</p>
                    </div>
                </Col>                  
                <Col className={`mb-1" ${style.directSecCol}`}>
                    {/* <div className={style.directSec}>
                        <p className={style.regSec} onClick={ () => { 
                            setSheet( 'registerPassword' );
                            // setUserStatus( 'register' ); 
                            setLoginWay( false );
                            // setsmsveristatus(false);
                            setHasSendSMS(false);
                            // setGotoSetPassword( true );
                            setShowRepassword(false);
                            setPasswordVeriStatus( false );
                            // setGotoSetEmail( false );  
                            setRegxpError( '' ); 
                            } }
                        >
                            <span>返回密碼設定</span>
                        </p>
                    </div> */}
                    {/* <div className={style.directSec}>
                        <p className={style.regSec} onClick={ () => { 
                            setSheet( 'LoginIndex' );
                            setLoginWay( false );
                            setHasSendSMS(false);
                            setShowRepassword(false);
                            setPasswordVeriStatus( false );
                            setRegxpError( '' ); 
                            } }
                        >
                            <span>前往登入</span>
                        </p>
                    </div> */}
                </Col>          
            </Form>       
        </Col>
    </Row>
    </>
  )
}

export default LoginIndex;