import React, { useState, useContext, useEffect, useRef } from 'react'
import style from '../UaaLogin/styles/UaaLogin.module.css';
import '../UaaLogin/styles/UaaLogin.css';
import { Container, Row, Col } from 'react-bootstrap';
import LoadingSpinner from '../Components/LoadingSpinner';
import Modal from 'react-bootstrap/Modal'
import popupStyle from '../Components/styles/popup.module.css';
import LoginParent from '../UaaPage/login/LoginParent';
import 'bootstrap/dist/css/bootstrap.min.css'
import PopupBanner from '../UaaPage/login/imgs/PopupBanner.png';

//antd
import { Steps, Typography, Layout } from 'antd';
// import './styles/UaaLogin.css';

//liff SDK
import liff from '@line/liff';

import AuthContext from '../UaaPage/login/components/AuthContext';
import { delay, set } from 'lodash';


import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from 'react-facebook-pixel'; // FB Pixel

import { phone } from 'phone'; // 手機驗證模組

function UaaLogin(props) {
  /*** FB Pixel 相關 ***/
  const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // Pixel組態設定
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FbPixelID, {}, options);
  // ReactPixel.pageView(); // For tracking page view
  const pixelPageView = () => {
    ReactPixel.trackCustom('瀏覽【上傳完課證明送瑜珈球－活動頁】', {content_category: 'kidpro_marketing_page', content_ids: 'view_advertising_page', content_name: '上傳完課證明送瑜珈球－活動頁'}); 
  }
  const pixelClickSI02 = (e) => {
    e.preventDefault();
    ReactPixel.trackCustom('點選【嬰幼兒感覺統合】', {content_category: 'kidpro_online_course', content_ids: 'si-0-2', content_name: '嬰幼兒感覺統合', content_type: 'product',
    currency: 'TWD', value: 2490 }); 
  }
  const pixelClickCC02 = (e) => {
    e.preventDefault();
    ReactPixel.trackCustom('點選【嬰幼兒父母必修課】', {content_category: 'kidpro_online_course', content_ids: 'cc-0-2', content_name: '嬰幼兒父母必修課', content_type: 'product',
    currency: 'TWD', value: 3990 }); 
  }
  const pixelClickSI212 = (e) => {
    e.preventDefault();
    ReactPixel.trackCustom('點選【兒童感覺統合】', {content_category: 'kidpro_online_course', content_ids: 'si-2-12', content_name: '兒童感覺統合', content_type: 'product',
    currency: 'TWD', value: 2490 }); 
  }
  const pixelClickAE31 = (e) => {
    e.preventDefault();
    ReactPixel.trackCustom('點選【兒童注意力與執行力訓練課程】', {content_category: 'kidpro_online_course', content_ids: 'ae3-1', content_name: '兒童注意力與執行力訓練課程', content_type: 'product',
    currency: 'TWD', value: 2490 }); 
  }
  const pixelGoToSubmitPage = () => {
    ReactPixel.trackCustom('點選【下一步】進入瑜珈球活動表單填寫頁', {content_category: 'kidpro_marketing_submit', content_ids: 'go_to_submitpage', content_name: '上傳完課證明送瑜珈球－活動頁－下一步'}); 
  }
  // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
  /*** END FB Pixel 相關 ***/

  const [cookies, setCookie] = useCookies(['cid']); // useCookies hook
  // console.log('cookies.cid', cookies.cid);

  /*** GA4相關 ***/
   // ReactGA.send({ hitType: "pageview", page: '/' }); // GA pageview設定
  /* page_view event parameters
    * page_location	string	No	https://example.com	The URL of the page.
    * client_id	string	No	ABC_123	The client ID.
    * language	string	No	en_us	The language for the client. See Language tags and codes.
    * page_encoding	string	No	UTF-8	The encoding of the page.
    * page_title	string	No	Home	The title of the page.
    * user_agent	string	No		The client's user agent.
    */
  /* 瀏覽頁面時，送出事件給GA4 */
  const sendGa4 = () => {
    ReactGA.event( 'page_view', {
      page_location: window.location.pathname,
      client_id: cookies.cid,
      language: 'zh_tw',
      page_encoding: 'UTF-8',
      page_title: 'Marketing - 宣傳頁',
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }
  /* 點選某項商品 */
  const Ga4ClickCC02 = (e) => {
    e.preventDefault();
    ReactGA.event( 'view_item', 
    {
      currency: 'TWD',
      value: 3990,
      items: [
        {
          item_id: 'cc-0-2',
          item_name: '嬰幼兒父母必修課',
          index: 1,
          item_category: 'kidpro_online_course',
          price: 3990
        }
      ]
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }
  const Ga4ClickSI02 = (e) => {
    e.preventDefault();
    ReactGA.event( 'view_item', 
    {
      currency: 'TWD',
      value: 2490,
      items: [
        {
          item_id: 'si-0-2',
          item_name: '嬰幼兒感覺統合',
          index: 2,
          item_category: 'kidpro_online_course',
          price: 2490
        }
      ]
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }  
  const Ga4ClickSI212 = (e) => {
    e.preventDefault();
    ReactGA.event( 'view_item', 
    {
      currency: 'TWD',
      value: 2490,
      items: [
        {
          item_id: 'si-0-2',
          item_name: '兒童感覺統合',
          index: 3,
          item_category: 'kidpro_online_course',
          price: 2490
        }
      ]
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }  
  const Ga4ClickAE31 = (e) => {
    e.preventDefault();
    ReactGA.event( 'view_item', 
    {
      currency: 'TWD',
      value: 2490,
      items: [
        {
          item_id: 'si-0-2',
          item_name: '兒童注意力與執行力訓練課程',
          index: 4,
          item_category: 'kidpro_online_course',
          price: 2490
        }
      ]
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }    

  /* 點選下一步 */
  const Ga4GoToSubmitPage = () => {
    ReactGA.event({
      category: "kidpro_marketing_submit",
      action: "go_to_submitPage",
      label: "點選【下一步】進入瑜珈球活動表單填寫頁",
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  }

  /* 瀏覽頁面的事件只在渲染時執行一次 */
  useEffect(() => {
    pixelPageView();
    sendGa4();
  }, []);
  /*** END GA4相關 ***/


  /* Ant API */
  const { Title } = Typography; // 標題
  const { Content } = Layout; // 主要內容區塊(Layout本身是組件，也可當API使用)
  const{ StepItem } = Steps;
  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL // KidPro 登入導向URL
  const kidprocertiURL = 'https://kid-pro.com/my-account/my-certificates/';  // KidPro 完課證明頁面
  const cc02URL = 'https://kid-pro.com/product/cc-0-2/';
  const si02URL = 'https://kid-pro.com/product/si0-2/';
  const si212URL = 'https://kid-pro.com/product/si2-12/';
  const ae31URL = 'https://kid-pro.com/product/ae3-1/';
  let loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : ""; // 取得Login Token
  let gotoKidproCerti = window.localStorage.getItem('loginToken') ? kidprologinURL + loginToken + '&redirectUrl=' + kidprocertiURL : kidprocertiURL; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向完課證明頁面
  let cc02 = window.localStorage.getItem('loginToken') ?  kidprologinURL + loginToken + '&redirectUrl=' + cc02URL : cc02URL;
  let si02 = window.localStorage.getItem('loginToken') ?  kidprologinURL + loginToken + '&redirectUrl=' + si02URL : si02URL;
  let si212 = window.localStorage.getItem('loginToken') ?  kidprologinURL + loginToken + '&redirectUrl=' + si212URL : si212URL;
  let ae31 = window.localStorage.getItem('loginToken') ?  kidprologinURL + loginToken + '&redirectUrl=' + ae31URL : ae31URL;
  // 測試用
  // let veriPhoneMod = phone( '+8860911087462' );
  // console.log('veriPhoneMod',veriPhoneMod);
  // let santiPhone = veriPhoneMod.phoneNumber;
  // console.log('santiPhone',santiPhone);

  const { setSheet } = props;

  const { authorized, setAuth, auth, setEmail, email } = useContext(AuthContext);
  //設定登入頁面line login 無綁定uaa
  const [NOuaabindLine, setNOuaabindLine] = useState(false);
  //設定login parent
  const [Loginparent, setLoginparent] = useState(0);
  //設定login token
  const [LoginToken, setLoginToken] = useState('');
  // 顯示錯誤訊息
  const [RegxpError, setRegxpError] = useState('');
  // const queryString = window.location.search;
  // console.log('queryString',queryString);
  // const urlParams = new URLSearchParams(queryString);
  // console.log('urlParams.get(liff.state)',urlParams.get('liff.state'));

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  let LIFF_STORE = window.localStorage.getItem('LIFF_STORE')
  let LIFF_STOREregister = window.localStorage.getItem('LIFF_STOREregister')
  const lineAccessToken = !! window.localStorage.getItem('lat') ? window.localStorage.getItem('lat') : '';

  const [Result, setResult] = useState({});

  //設定Modal 顯示與否
  const [show, setShow] = useState(false);
  //設定copy按鈕反饋checkMark
  const [ShowCheckMark, setShowCheckMark] = useState(false);

  const [loginTokenState, setloginTokenState] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Popup = (props) => {
    const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
    return (
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              <div className={popupStyle.modalClose} onClick={(event) => { handleClose(); }}>x</div>
              <div className={popupStyle.modalHeader} >
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img src={PopupBanner}></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody} >
                <LoginParent className={popupStyle.modalLoginParent} NOuaabindLine={NOuaabindLine} loginTokenState={loginTokenState} Loginparent={Loginparent} UserData={email} setNOuaabindLine={setNOuaabindLine} LoginToken={LoginToken}  ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      </>
    );
  }
  
  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('smsVeriStatus');
      window.localStorage.removeItem('phoneNumber');
      window.localStorage.removeItem('url');
    // }, false);
  }

  /* 案X清除Local Storage並登出LINE、FB */
  // const clearStorage = (event) => {
  //   event.preventDefault();
  //   // 如果LINE有登入，執行LINE登出
  //   const isLoggedIn = liff.isLoggedIn();
  //   if (isLoggedIn) {
  //     liff.logout();
  //   }
  //   // 如果FB物件存在，且為登入中，將FB登出
  //   if (!!window.FB) {
  //     window.FB.getLoginStatus(function (response) {
  //       if (response.status === 'connected') {
  //         window.FB.logout(function (response) {
  //           // // console.log('handleFBLogout', response);
  //         });
  //       }
  //     });
  //   }
  //   localStorage.clear();
  //   setNOuaabindLine(false);
  //   setEmail(null);
  //   setSheet('LoginIndex');
  // }

  //設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);

  /* 檢查使用者是否參加過活動或已登記活動表單(由官網登入返回後再做一次檢查並導向) */
  const checkActivityAvailable = () => {
    const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
    const BearerToken = 'Bearer ' + loginToken;
    // handleShow();
    setIsLoading(true);
    // POST送出手機資料，驗證是否成功
    fetch('https://' + apiURL + '/api/marketing/event_user/share_yoga', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': BearerToken
        },
    })
    .then((r) => r.json())
    .then((result) => {
        if ( result.status === 200 && !! result.data ) {
          setIsLoading(false);
          handleClose();
          alert('您已經登記過此活動囉！'); 
          window.location.assign( window.location.protocol + "//" + window.location.host + "/CountingPage" );
        } else if ( result.status === 400 && result.errorCode === 1001 ) {
          setIsLoading(false);
          handleClose();
          alert('您已經參加過上次活動囉！'); 
          window.location.assign( window.location.protocol + "//" + window.location.host + "/Forbidden" );
        } else if ( result.status === 404 && result.errorCode === 1002 ) {
          setIsLoading(false);
          handleClose();
          window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
        } else if ( result.status === 403 && result.errorCode === 1003 ) {
          setIsLoading(false);
          handleClose();
          alert('您還沒有取得完課證明喔！');
        } else {
          setIsLoading(false);
          alert('伺服器忙線中，請稍後再試！');
          handleClose();
          window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
        }
    })
    .catch((error) => {
        setIsLoading(false);
        alert('伺服器忙線中，請稍後再試！');
        handleClose();
        window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
    }) // END Fetch          
  }  

  /* 檢查登入狀態，有JWT直接跳到填寫表單頁面；無JWT則彈出登入視窗 */
  function checkJWT() {
    // console.log(loginToken);
    if( !! loginToken ) {
      Ga4GoToSubmitPage();
      pixelGoToSubmitPage();

      // checkActivityAvailable();
      // window.location.href = '/Admin';

      // window.location.assign( "/CheckActivity?redir=kidpro" ); // 檢查活動並導向相應頁面 
      // Hide loading screen 
    } else {
      // window.location.reload();
      // 請先完成登入，才能前往登記活動！
      handleShow();
      // window.location.assign('/#');
    }
  }

  const copyText = () => {
    let copy = '#KidPro醫學級兒童發展教育團隊_恬兒職能治療師\n#輸入底下優惠碼享購課94折\n【#KidPro科學育兒在你身邊】';
    // let copy = '@恬兒職能治療師-醫學領域的兒童發展專家\n#KidPro優惠碼【科學育兒】購買線上課程享94折';
    navigator.clipboard.writeText(copy)
      .then(() => {
        //複製完成顯示checkMark
        setShowCheckMark(true);
        //複製完成三秒後checkMark消失
        setTimeout(() => {
          setShowCheckMark(false);
        }, 3000);
      })
      .catch(() => {
        setShowCheckMark(false);
      })
  }


  const lineid = process.env.REACT_APP_LINE_APP_ID;
  // // console.log('lineid',lineid);
  let line_liff_loginData = {};


  /* 點LINE註冊，檢驗使用者是否已綁定過LINE。 (LINE登入走LoginIndex->LoginEncode->CheckActivity->導向KidPro官網登入－> ( 1.有JWT: 回CheckActivity檢驗活動狀態－>導向首頁/submitpage/countingpage ) ( 2.無JWT： 導向首頁繼續下方判斷 ) */
  const linelogin = async () => {
    const init = await liff.init({
      liffId: lineid, // Use own liffId
      // withLoginOnExternalBrowser: true, // Enable automatic login process
    }).catch((err) => {
        // Todo: retry init.
        // // console.log('err',err);
    });
    const isLoggedIn = liff.isLoggedIn();
    // // console.log('line',isLoggedIn);
    if( isLoggedIn === true ) {
      let redirectUrl = window.location.href
      const accessToken = liff.getAccessToken();
      line_liff_loginData = {
        accessToken: accessToken,
      }
      // // console.log('line_bindData',line_liff_loginData);
      // const loginToken = window.localStorage.getItem('loginToken');
      // const BearerToken = 'Bearer ' + loginToken;
      fetch('https://uaa.kid-pro.com/api/uaa/line_liff_login', {
        method: 'POST',
        body: JSON.stringify(line_liff_loginData),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((r) => r.json())
      .then((result) => {
        // // console.log('result',result);
        if( result.status === 200 && ! LIFF_STOREregister ) { // 1. LINE登入/其他狀況，使用者已綁定，導向CheckActivity進行登入並檢查活動
          // 從line登入頁面進來
          // navbar show 登出 有token  NOuaabindLine false(有綁定line and uaa)
          window.localStorage.setItem( 'loginToken', result.data.loginToken );
          setNOuaabindLine(false);
          setAuth({...auth,authorized:true});
          if( !! loginToken ) {
            // window.location.assign( "/CheckActivity?redir=kidpro" );
          }
          // // console.log('01');
        } else if( result.status === 200 && LIFF_STOREregister ) { // 2. LINE註冊，使用者已綁定
          // // console.log('02');
          // 從註冊頁面line登入進來，有帳號uaa+line，進入帳號已存在popup
          window.localStorage.setItem( 'lineRegisterLoginToken', result.data.loginToken ); // 只記錄login token，而不做登入；待使用者點選登入才登入
          const BearerToken = 'Bearer ' + result.data.loginToken;
          fetch('https://uaa.kid-pro.com/api/uaa/user_pofile', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': BearerToken
            }
          }) 
          .then((r) => r.json() )
          .then((result) => {
            if( ! window.localStorage.getItem('loginToken') ) {
              // setSheet('accountExist');
              setEmail(result.data.email);
              handleShow(); // 未登入則顯示帳號已存在，問使用者是否登入
            } else {
              handleClose(); // 已登入則不彈出登入視窗
            }
          })
          .catch((error) => {
            // // console.log('error', error);
            alert('伺服器忙線中，請稍候再試！');
          });
        } else if( result.statusCode === 400 && LIFF_STOREregister ) { // 3. LINE註冊，使用者未綁定且無帳號
          //從註冊頁面line登入進來，無帳號，進入註冊
          // // console.log('03');
          handleShow();
          // setSheet('registerPhone');
          setSheet('NOuaabindLine');
        } else if( result.statusCode === 400 && ! LIFF_STOREregister ) { // 4. LINE登入，使用者未綁定
          //從line登入頁面進來 無綁定 進入NoLineDataLogin popup
          handleShow();
          //setNOuaabindLine(false);
          setSheet('NOuaabindLine');
          // // console.log('04');
          //show 登入 
        } else if( result.statusCode === 403 && LIFF_STOREregister ) { // 3. LINE註冊，使用者未綁定且無帳號
          //404 資料庫找不到line 資料
          //從註冊頁面line登入進來，無帳號，進入註冊
          // // console.log('05');
          handleShow();
          setSheet('NOuaabindLine');
        } else if( result.statusCode === 403 && ! LIFF_STOREregister ) { // 4. LINE登入，使用者未綁定
            //404 資料庫找不到line 資料
            //從line登入頁面進來 無綁定 進入NoLineDataLogin popup
            handleShow(); //show 登入
            // setNOuaabindLine(true);
            setSheet('NOuaabindLine');
            // // console.log('06');
        } else {
          alert('伺服器忙線中，請稍候再試！');
          // // console.log('LINE登入失敗！')
          //show 登入
        }
      });
    }
  }

  /* 導向瑜珈球頁面 */
  const redirToYogaball = () => {
    const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : '';  // 由localStorage取得loginToken(設置登入狀態)
    const yogaballUrl = window.localStorage.getItem('yogaballUrl') ? window.localStorage.getItem('yogaballUrl') : ''; // 由localStorage取得瑜珈球頁面連結
    if (!!loginToken && !!yogaballUrl) { // 若以https://liff.line.me/{LineDevID}?redir=...進行登入，取得先前存入localStorage的連結，加入JWT進行官網登入導向
      const kidproLoginYogaballUrl = 'https://kid-pro.com/wp-json/simple-jwt-login/v1/autologin?JWT=' + loginToken + "&redirectUrl=" + yogaballUrl;
      // // console.log('kidproLoginYogaballUrl',kidproLoginYogaballUrl);
      // // console.log('導向瑜珈球頁面前');
      window.localStorage.setItem('yogaballUrl',''); // 清空導向值
      window.location.assign( kidproLoginYogaballUrl );
      }
  }

  /* 彈出註冊成功 */
  const popupRegisterSuccess = () => {
    let queryString = window.location.search; // 由驗證Email之連結取得url query字串
    let urlParams = new URLSearchParams(queryString); // 分析url query字串
    let regStatus = urlParams.get('register') // 取得url query字串中的註冊狀態值
    // // console.log('redir', redir);
    if( regStatus === 'true' ) {
        // // console.log('regStatus', regStatus );
        setSheet('registerSucess');
        handleShow();
    }
  }

  useEffect(() => {
    linelogin();
    redirToYogaball();
    previousPageClearStorage();
    // checkUserLineBind();
    // popupRegisterSuccess();
  }, []);

  return (
    <>
      <Popup NOuaabindLine={NOuaabindLine} LoginToken={LoginToken} ></Popup>
    </>
  )
}

export default UaaLogin